import { Box, Button, Card, CardContent, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, styled, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCalculatePaymentScheduleQuery, useCreateLoanMutation, useLoanQuery, usePaymentsQuery, useUpdateLoanMutation } from "../client/query-client";
import { Device, LoanEvent } from "../client/motal-api/generated";
import dayjs from "dayjs";
import { PaymentStatusChip } from "../component/PaymentStatusChip";
import { LoanStatusChip } from "../component/LoanStatusChip";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect } from "react";
import { PaymentTypeChip } from "../component/PaymentTypeChip";
import toast from 'react-hot-toast';
import "dayjs/locale/th"
import LoanUploadZone from "../component/LoanUploadZone";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useShopContext } from "../context/ShopContextProvider";
import { validateThaiId } from "../util/thai-id-validator";



type LoanDetailScreenProps = {
    mode: 'create' | 'update'
};

const CustomInputLabel = styled(InputLabel)({
    position: "relative",
    transform: "none",
    marginBottom: "5px",
});

export const LoanDetailScreen = (props: LoanDetailScreenProps) => {

    const nav = useNavigate();
    const createLoanMutation = useCreateLoanMutation();
    const updateLoanMutation = useUpdateLoanMutation();
    const shopCtx = useShopContext()

    const { shopId, id } = useParams()

    const loanQuery = useLoanQuery(id || '');
    const paymentsQuery = usePaymentsQuery(id || '', shopId || '', 100, props.mode === 'update');
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const form = useForm<LoanEvent>({
        defaultValues: {
            loanFundSource: LoanEvent.loanFundSource.SHOP,
            status: LoanEvent.status.NORMAL,
            transactionDate: dayjs().format("YYYY-MM-DD"),
            shopId: shopId,
            repaymentTermFrequencyType: LoanEvent.repaymentTermFrequencyType.MONTH,
            device: {
                usedStatus: Device.usedStatus.NEW
            },
            activeTermNumber: loanQuery.data?.activeTermNumber && 1,
            downPaymentAmount: 0
        },
        mode: 'all'
    });

    useEffect(() => {
        if (!loanQuery.isLoading
            && loanQuery.data
            && form
            && props.mode === 'update') {
            form.reset(loanQuery.data);
        }
    }, [loanQuery.isLoading, loanQuery.data, form, props.mode]);

    const paymentSchedulePreviewQuery = useCalculatePaymentScheduleQuery({
        downPaymentAmount: form.watch('downPaymentAmount'),
        downPaymentDate: form.watch('downPaymentDate'),
        firstRepaymentDate: form.watch('firstRepaymentDate'),
        repaymentTermAmount: form.watch('repaymentTermAmount'),
        repaymentTermCount: form.watch('repaymentTermCount'),
        repaymentTermFrequency: form.watch('repaymentTermFrequency'),
        repaymentTermFrequencyType: form.watch('repaymentTermFrequencyType'),
    }, props.mode === 'create');

    const onSubmit = () => {
        form.handleSubmit((data) => {
            if (props.mode === 'create') {
                createLoanMutation.mutate(data, {
                    onSuccess: () => {
                        toast.success('สร้างสินเชื่อสำเร็จ')
                        nav(`/${shopId}/loan`);
                    }
                });
            } else if (props.mode === 'update') {
                updateLoanMutation.mutate({ loan: data, loanId: id! }, {
                    onSuccess: () => {
                        toast.success('อัพเดทสินเชื่อสำเร็จ')
                        loanQuery.refetch();
                        // nav(`/${shopId}/loan`);
                    }
                });
            }
        })()
    }


    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
                <Box p={2} mb={16}>
                    <Typography variant="h5" p={2}>{
                        props.mode === 'create'
                            ? 'สร้างสินเชื่อ'
                            : `สินเชื่อลำดับที่ : ${loanQuery.data?.sequenceNumber}`
                    }</Typography>
                    <Card sx={{ my: 2, width: { xs: "100%", lg: "calc(98% + 16px)" } }}>
                        <CardContent sx={{ padding: 2, paddingBottom: 1 }}>

                            <Stack direction={{ lg: 'row' }} justifyContent='space-between' gap={2}>
                                <FormControl fullWidth>
                                    <CustomInputLabel>วันที่ (วัน/เดือน/ปี ค.ศ.)</CustomInputLabel>
                                    <Controller control={form.control}
                                        name="transactionDate"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <DatePicker {...field}
                                                format="DD/MM/YYYY"

                                                value={field.value ? dayjs(field.value) : null}  // Convert to Dayjs
                                                onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                        )} />
                                    {form.formState.errors.transactionDate && (
                                        <FormHelperText error>กรุณาระบุวัน</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth>
                                    <CustomInputLabel>{'หมายเลขเคส (ไม่จำเป็น)'}</CustomInputLabel>
                                    <TextField {...form.register('referenceId', { required: false })} size='small' variant="outlined" />
                                </FormControl>
                                <Stack>
                                    <CustomInputLabel>สถานะการผ่อน</CustomInputLabel>
                                    <Box sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={form.control}
                                            name="status"
                                            render={({ field }) => {
                                                return (<LoanStatusChip selectable={props.mode !== 'create'} status={field.value!} onSelect={field.onChange} />)
                                            }}
                                        />
                                    </Box>
                                </Stack>

                                <Box p={1} justifyContent='center' alignItems='center'>
                                    {props.mode === 'update'
                                        ? <Button
                                            variant='contained'
                                            size='medium'
                                            onClick={() => nav(`/${shopId}/loan/${id}/create-payment`)}
                                            sx={{ height: '56px', width: { xs: '100%', lg: 200 } }}
                                        >
                                            ชำระเงิน
                                        </Button>
                                        : <></>
                                    }
                                </Box>
                            </Stack>

                        </CardContent>
                    </Card>
                    {id ?
                        <Card sx={{ my: 2, width: { xs: "100%", lg: "calc(98% + 16px)" } }}>
                            <CardContent sx={{ padding: 2, paddingBottom: 1 }}>
                                <Stack direction={{ lg: 'row' }} gap={{ xs: 2, lg: 8 }} >
                                    <Box>
                                        <FormControl sx={{ width: 100 }}>
                                            <CustomInputLabel>งวดปัจจุบัน</CustomInputLabel>
                                            <Stack direction='row' alignItems='center'>
                                                <Typography sx={{ lineHeight: '32px' }}>{loanQuery.data?.activeTermNumber ? `${loanQuery.data?.activeTermNumber} / ${loanQuery.data.repaymentTermCount}` : "-"}</Typography>
                                            </Stack>
                                        </FormControl>

                                    </Box>
                                    <Box>
                                        <CustomInputLabel>กำหนดชำระ</CustomInputLabel>
                                        <Stack direction='row' alignItems='center'>
                                            <Typography sx={{ lineHeight: '32px' }}
                                                color={loanQuery.data?.upcomingPaymentDays! >= 0 ? '#43a047' : '#f44336'}
                                            >{loanQuery.data?.activeTermScheduledDate
                                                ? `${dayjs(loanQuery.data?.activeTermScheduledDate).format('DD/MM/YYYY')} (${loanQuery.data?.upcomingPaymentDays === 0
                                                    ? 'ถึงกำหนด'
                                                    : loanQuery.data?.upcomingPaymentDays! > 0
                                                        ? `อีก ${loanQuery.data?.upcomingPaymentDays} วัน`
                                                        : `เกิน ${-loanQuery.data?.upcomingPaymentDays!} วัน`})`
                                                : '-'}</Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <CustomInputLabel>ลิ้งสำหรับลูกค้า</CustomInputLabel>
                                        <Stack direction='row' gap={1} alignItems='flex-end' justifyContent='flex-start'>
                                            <Typography sx={{ lineHeight: '32px' }}>{id ? `https://installment.motalvip.com?loanId=${id}` : '-'}</Typography>
                                            <IconButton size="small" onClick={() => {
                                                navigator.clipboard.writeText(`https://installment.motalvip.com?loanId=${id}`)
                                                    .then(() => {
                                                        toast.success('คัดลอกสำเร็จ');
                                                    })
                                            }}>
                                                <ContentCopyIcon fontSize='inherit' />
                                            </IconButton>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                        : <></>
                    }
                    <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" >

                        <Card sx={{ width: { xs: '100%', lg: '49%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="h6">ข้อมูลลูกค้า</Typography>
                                    <Stack direction='row' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>ชื่อ</CustomInputLabel>
                                            <TextField {...form.register('customer.firstName')} size='small' variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>นามสกุล</CustomInputLabel>
                                            <TextField {...form.register('customer.lastName')} size='small' variant="outlined" />
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth error={!!form.formState.errors.customer?.citizenId}>
                                            <CustomInputLabel>หมายเลขประจำตัวประชาชน</CustomInputLabel>
                                            <Controller control={form.control}
                                                rules={{
                                                    pattern: { value: /^[0-9]{13}$/, message: "หมายเลขประจำตัวประชาชนไม่ถูกต้อง" },
                                                    validate: (value) => {
                                                        if (!value && !form.watch('customer.passportId')) {
                                                            return 'กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต';
                                                        }
                                                        if (!validateThaiId(value?.toString() || '') && !form.watch('customer.passportId')) {
                                                            return 'หมายเลขประจำตัวประชาชนไม่ถูกต้อง';
                                                        }
                                                        return true;
                                                    }
                                                }}
                                                name="customer.citizenId" render={({ field }) => (
                                                    <TextField
                                                        value={field.value ? field.value : ''}
                                                        onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                        size='small'
                                                        variant="outlined" />
                                                )} />
                                            {form.formState.errors.customer?.citizenId && (
                                                <FormHelperText error>{`${form.formState.errors.customer?.citizenId.message}`}</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth error={!!form.formState.errors.customer?.passportId}>
                                            <CustomInputLabel>หมายเลขพาสปอร์ต</CustomInputLabel>
                                            <Controller control={form.control}
                                                rules={{
                                                    pattern: { value: /^[A-Za-z0-9]*$/, message: "หมายเลขพาสปอร์ตไม่ถูกต้อง" },
                                                    validate: (value) => {
                                                        if (!value && !form.watch('customer.citizenId')) {
                                                            return 'กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต';
                                                        }
                                                        return true;
                                                    }
                                                }}
                                                name="customer.passportId" render={({ field }) => (
                                                    <TextField
                                                        value={field.value ? field.value : ''}
                                                        onChange={(e) => field.onChange(e.target.value ? e.target.value : NaN)}
                                                        size='small'
                                                        variant="outlined" />
                                                )} />
                                            {form.formState.errors.customer?.passportId && (
                                                <FormHelperText error>{`${form.formState.errors.customer?.passportId.message}`}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Stack>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>ที่อยู่ปัจจุบัน</CustomInputLabel>
                                        <TextField {...form.register('customer.address')} size='small' variant="outlined" />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>ที่อยู่ตามบัตรประชาชน</CustomInputLabel>
                                        <TextField {...form.register('customer.citizenAddress')} size='small' variant="outlined" />
                                    </FormControl>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>อาชีพ</CustomInputLabel>
                                            <TextField {...form.register('customer.occupation')} size='small' variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>สถานที่ทำงาน</CustomInputLabel>
                                            <TextField {...form.register('customer.workPlace')} size='small' variant="outlined" />
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>เบอร์โทรศัพท์</CustomInputLabel>
                                            <TextField {...form.register('customer.phoneNumber')} size='small' variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>อีเมล</CustomInputLabel>
                                            <TextField {...form.register('customer.email')} size='small' variant="outlined" />
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>LINE ID</CustomInputLabel>
                                            <TextField {...form.register('customer.lineId')} size='small' variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>Facebook</CustomInputLabel>
                                            <TextField {...form.register('customer.facebookUrl')} size='small' variant="outlined" />
                                        </FormControl>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>

                        <Card sx={{ width: { xs: '100%', lg: '49%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="h6">ข้อมูลเครื่อง</Typography>
                                    <Controller control={form.control} name="device.usedStatus" render={({ field }) => (
                                        <RadioGroup {...field} row>
                                            <FormControlLabel value={Device.usedStatus.NEW} label='สินค้าใหม่' control={<Radio />} />
                                            <FormControlLabel value={Device.usedStatus.USED} label='สินค้าใช้แล้ว' control={<Radio />} />
                                        </RadioGroup>
                                    )} />
                                    <FormControl fullWidth>
                                        <CustomInputLabel>แบรนด์</CustomInputLabel>
                                        <TextField {...form.register('device.brand')} size='small' variant="outlined" />
                                    </FormControl>
                                    <Stack direction='row' gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>รุ่น</CustomInputLabel>
                                            <TextField {...form.register('device.model')} size='small' variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>สี</CustomInputLabel>
                                            <TextField {...form.register('device.color')} size='small' variant="outlined" />
                                        </FormControl>
                                    </Stack>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>รายละเอียด (อุปกรณ์เสริม, อื่นๆ)</CustomInputLabel>
                                        <TextField {...form.register('device.description')} size='small' variant="outlined" />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>อีมี่ (IMEI)</CustomInputLabel>
                                        <TextField {...form.register('device.imei')} size='small' variant="outlined" />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>Serial Number</CustomInputLabel>
                                        <TextField {...form.register('device.serialNumber')} size='small' variant="outlined" />
                                    </FormControl>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                    <Card sx={{ my: 2, width: { xs: '100%', lg: 'calc(98% + 16px)' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack gap={2}>
                                <Typography variant="h6">ข้อมูลสินเชื่อ</Typography>
                                <Controller control={form.control} name="loanFundSource" render={({ field }) => (
                                    <RadioGroup row {...field} value={field.value ? field.value : ''} onChange={(e) => e ? field.onChange(e) : field.onChange()}>
                                        <FormControlLabel value={LoanEvent.loanFundSource.SHOP} label='อนุมัติสินเชื่อจากร้านค้า' control={<Radio />} />
                                        <FormControlLabel disabled value={LoanEvent.loanFundSource.FINANCE} label='ขออนุมัติสินเชื่อจากผู้ให้บริการ (Coming Soon)' control={<Radio />} />
                                    </RadioGroup>
                                )} />
                                <Stack direction={{ lg: 'row' }} gap={2}>
                                    <FormControl fullWidth>
                                        <CustomInputLabel disabled={props.mode === 'update'}>ยอดเงินดาวน์</CustomInputLabel>
                                        <Controller control={form.control}
                                            rules={{ min: 0, required: true, }}
                                            name="downPaymentAmount" render={({ field }) => (
                                                <TextField
                                                    disabled={props.mode === 'update'}
                                                    type="number"
                                                    placeholder="0"
                                                    value={field.value ? field.value : ''}
                                                    onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                    size='small' variant="outlined" />
                                            )} />
                                        {form.formState.errors.downPaymentAmount && (
                                            <FormHelperText error>ไม่ถูกต้อง</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>ยอดเงินผ่อนต่องวด</CustomInputLabel>
                                        <Controller control={form.control}
                                            rules={{ min: 0, required: true }}
                                            name="repaymentTermAmount" render={({ field }) => (
                                                <TextField disabled={props.mode === 'update'} type="number"
                                                    placeholder="0"
                                                    value={field.value ? field.value : ''}
                                                    onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                    size='small' variant="outlined" />
                                            )} />
                                        {form.formState.errors.repaymentTermAmount && (
                                            <FormHelperText error>กรุณาระบุยอดเงินผ่อนต่องวด</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>จำนวนงวด</CustomInputLabel>
                                        <Controller rules={{ required: true, min: 0 }} control={form.control} name="repaymentTermCount" render={({ field }) => (
                                            <TextField disabled={props.mode === 'update'} type="number"
                                                value={field.value ? field.value : ''}
                                                onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                size='small' variant="outlined" />
                                        )} />
                                        {form.formState.errors.repaymentTermCount && (
                                            <FormHelperText error>กรุณาระบุจำนวนงวด</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>ราคาเช่าซื้อรวม (คำนวณ)</CustomInputLabel>
                                        {/* <Controller control={form.control} name="totalAmount" render={({ field }) => ( */}
                                        <TextField
                                            disabled={props.mode === 'update'}
                                            type="number"
                                            value={(form.watch('downPaymentAmount') || 0) + ((form.watch('repaymentTermAmount') || 0) * (form.watch('repaymentTermCount') || 0))}
                                            size='small' variant="outlined" />

                                        {/* )} /> */}
                                    </FormControl>
                                </Stack>
                                <Stack direction={{ lg: 'row' }} gap={2}>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>วันที่ชำระเงินดาวน์ (วัน/เดือน/ปี ค.ศ.)</CustomInputLabel>
                                        <Controller control={form.control} name="downPaymentDate" render={({ field }) => (
                                            <DatePicker
                                                disabled={props.mode === 'update'}
                                                format="DD/MM/YYYY"
                                                value={field.value ? dayjs(field.value) : null}  // Convert to Dayjs
                                                onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                        )} />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>วันที่เริ่มผ่อนงวดแรก (วัน/เดือน/ปี ค.ศ.)</CustomInputLabel>
                                        <Controller control={form.control} name="firstRepaymentDate" render={({ field }) => (
                                            <DatePicker
                                                disabled={props.mode === 'update'}
                                                format="DD/MM/YYYY"
                                                value={field.value ? dayjs(field.value) : null}  // Convert to Dayjs
                                                onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                        )} />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>ระยะเวลาชำระต่องวดทุกๆ</CustomInputLabel>
                                        <Stack direction='row' gap={1}>
                                            <Stack direction='column' gap={1}>
                                                <Controller
                                                    rules={{ required: true, min: 0 }}
                                                    control={form.control} name="repaymentTermFrequency" render={({ field }) => (
                                                        <TextField disabled={props.mode === 'update'} type="number"
                                                            value={field.value ? field.value : ''}
                                                            onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                            size='small' variant="outlined" />
                                                    )} />
                                                {form.formState.errors.repaymentTermFrequency && (
                                                    <FormHelperText error>กรุณาระบุ</FormHelperText>
                                                )}
                                            </Stack>
                                            <Controller control={form.control} name="repaymentTermFrequencyType" render={({ field }) => (
                                                <Select
                                                    disabled={props.mode === 'update'}
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    {...field}
                                                    size='small'
                                                >
                                                    <MenuItem value={LoanEvent.repaymentTermFrequencyType.MONTH}>เดือน</MenuItem>
                                                    <MenuItem value={LoanEvent.repaymentTermFrequencyType.DAY}>วัน</MenuItem>
                                                </Select>
                                            )} />
                                        </Stack>
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" >

                        <Card sx={{ width: { xs: '100%', lg: '49%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="h6">ข้อมูลต้นทุน</Typography>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>ต้นทุนเครื่อง</CustomInputLabel>
                                            <Controller control={form.control} name="costAmount" render={({ field }) => (
                                                <TextField type="number"
                                                    value={field.value ? field.value : ''}
                                                    onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                    size='small' variant="outlined" />
                                            )} />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>{`ค่าคอม (ไม่จำเป็น)`}</CustomInputLabel>
                                            <Controller control={form.control} name="commissionAmount" render={({ field }) => (
                                                <TextField type="number"
                                                    value={field.value ? field.value : ''}
                                                    onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                    size='small' variant="outlined" />
                                            )} />
                                        </FormControl>
                                    </Stack>
                                    {shopCtx.isOwner && <Box>
                                        <CustomInputLabel>กำไร</CustomInputLabel>
                                        <Typography
                                            mt={1}
                                            color={loanQuery.data?.totalProfit && loanQuery.data?.totalProfit < 0 ? '#f44336' : '#43a047'}
                                        >{loanQuery.data?.totalProfit ? currencyFormat.format(loanQuery.data?.totalProfit) : "-"}</Typography>
                                    </Box>}
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { xs: '100%', lg: '49%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="h6">ไฟล์</Typography>
                                    <Controller
                                        control={form.control}
                                        name="files"
                                        render={({ field }) => {
                                            return (<LoanUploadZone loanId={id!} initialFiles={field.value || []} onFilesChange={field.onChange} />)
                                        }}
                                    />

                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                    <Card sx={{ my: 2, width: { xs: "100%", lg: "calc(98% + 16px)" } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack gap={2}>
                                <Typography variant="h6">
                                    {props.mode === 'create' ? 'ประวัติการชำระเงิน' : 'ประวัติการชำระเงิน'}
                                </Typography>
                                <Grid container >
                                    <Grid item xs={3}><Typography>วันที่ชำระเงิน</Typography></Grid>
                                    <Grid item xs={1}><Typography>งวดที่</Typography></Grid>
                                    <Grid item xs={2}><Typography>ประเภทการชำระเงิน</Typography></Grid>
                                    <Grid item xs={2}><Typography>สถานะ</Typography></Grid>
                                    <Grid item xs={3}><Typography align='right' pr={3}>จำนวนเงิน (บาท)</Typography></Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                                <Divider />

                                <Stack gap={1}>

                                    {(props.mode === 'create' ? paymentSchedulePreviewQuery.data || [] : paymentsQuery.data?.content || []).map((payment, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
                                                padding: 1,
                                                borderRadius: 1,
                                            }}
                                        >
                                            <Grid container alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography>{payment.transactionAt ? dayjs(payment.transactionAt).format('DD/MM/YYYY') : '-'}</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Typography>{payment.repaymentTermNumber ? payment.repaymentTermNumber : "-"}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <PaymentTypeChip type={payment.type!} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <PaymentStatusChip status={payment.status!} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography align='right' pr={3}>{currencyFormat.format(payment.amount!)}</Typography>
                                                </Grid>
                                                <Grid item xs={1} >
                                                    <Stack alignContent='right' justifyContent='right' alignItems='flex-end' justifyItems='flex-end'>
                                                        {props.mode === 'update'
                                                            ?
                                                            <IconButton onClick={() => nav(`/${shopId}/payment/${payment.id}`)}>
                                                                <ArrowForwardIosIcon />
                                                            </IconButton> : <></>
                                                        }
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))}
                                </Stack>

                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </LocalizationProvider >
            <Box sx={{ visibility: props.mode === 'create' || (props.mode === 'update' && form.formState.isDirty) ? 'visible' : 'hidden', p: 2, bgcolor: 'white', width: { xs: '100%', sm: 'calc(100% - 240px)' }, position: 'fixed', bottom: 0, zIndex: 99 }} >
                <Stack direction='row' gap={1} justifyContent='center'>
                    <Button variant='outlined' size='large'
                        onClick={() => nav(`/${shopId}/loan`)}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>ยกเลิก</Button>
                    <Button variant='contained' size='large'
                        onClick={onSubmit}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>บันทึก</Button>
                </Stack>
            </Box>
        </>
    );
};
