/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminCalculateRepaymentTermRequest } from '../models/AdminCalculateRepaymentTermRequest';
import type { AdminShopExtendRequest } from '../models/AdminShopExtendRequest';
import type { AdminShopUpdateRequest } from '../models/AdminShopUpdateRequest';
import type { Loan } from '../models/Loan';
import type { PaginatedResponseLoanEvent } from '../models/PaginatedResponseLoanEvent';
import type { PaginatedResponseShopEvent } from '../models/PaginatedResponseShopEvent';
import type { PaginatedResponseUser } from '../models/PaginatedResponseUser';
import type { Shop } from '../models/Shop';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any OK
     * @throws ApiError
     */
    public migrateFiles(): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v1/migrate-files',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public fixShops(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v1/fix-shops',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public cleanPayments(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v1/clean-payments',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns Loan OK
     * @throws ApiError
     */
    public calculateRepaymentTerms({
        requestBody,
    }: {
        requestBody: AdminCalculateRepaymentTermRequest,
    }): CancelablePromise<Loan> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v1/calculate-repayment-terms',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public calculateRepaymentTermsAll(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v1/calculate-repayment-terms-all',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteShop({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/v1/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns Shop OK
     * @throws ApiError
     */
    public updateShop1({
        shopId,
        requestBody,
    }: {
        shopId: string,
        requestBody: AdminShopUpdateRequest,
    }): CancelablePromise<Shop> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/v1/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns Shop OK
     * @throws ApiError
     */
    public extendShop({
        shopId,
        requestBody,
    }: {
        shopId: string,
        requestBody: AdminShopExtendRequest,
    }): CancelablePromise<Shop> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/v1/shops/{shopId}/extend',
            path: {
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedResponseUser OK
     * @throws ApiError
     */
    public getUsers({
        shopId,
        email,
        search,
        page = 1,
        limit = 10,
    }: {
        shopId?: string,
        email?: string,
        search?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedResponseUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/v1/users',
            query: {
                'shopId': shopId,
                'email': email,
                'search': search,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedResponseShopEvent OK
     * @throws ApiError
     */
    public getShops1({
        search,
        sortBy = 'createdAt',
        sort = 'desc',
        page = 1,
        limit = 10,
    }: {
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedResponseShopEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/v1/shops',
            query: {
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns User OK
     * @throws ApiError
     */
    public getAdminProfile(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/v1/me',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedResponseLoanEvent OK
     * @throws ApiError
     */
    public getLoans({
        shopId,
        search,
        scheduledDaysFrom,
        scheduledDaysTo,
        status,
        sortBy = 'transactionDate',
        sort = 'desc',
        page = 1,
        limit = 10,
    }: {
        shopId?: string,
        search?: string,
        scheduledDaysFrom?: number,
        scheduledDaysTo?: number,
        status?: Array<'NORMAL' | 'CLOSED' | 'REFUND' | 'FOLLOWING_UP' | 'CANNOT_CONTACT_CUSTOMER' | 'NON_PERFORMING' | 'REPORTED' | 'CANCEL'>,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedResponseLoanEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/v1/loans',
            query: {
                'shopId': shopId,
                'search': search,
                'scheduledDaysFrom': scheduledDaysFrom,
                'scheduledDaysTo': scheduledDaysTo,
                'status': status,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
