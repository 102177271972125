/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopMemberUser = {
    id?: string;
    clerkUserId?: string;
    primaryEmail?: string;
    phoneNumber?: string;
    nationalId?: string;
    firstName?: string;
    laseName?: string;
    shopRole?: ShopMemberUser.shopRole;
    systemRole?: ShopMemberUser.systemRole;
    lastLoginAt?: string;
    createdAt?: string;
    updatedAt?: string;
};
export namespace ShopMemberUser {
    export enum shopRole {
        OWNER = 'OWNER',
        STAFF = 'STAFF',
    }
    export enum systemRole {
        ADMIN = 'ADMIN',
        MEMBER = 'MEMBER',
    }
}

