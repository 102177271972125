import React, { useState, MouseEvent, useEffect } from 'react';
import { Chip, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { keyframes } from '@emotion/react';

interface DropdownChipOption {
    value: string;
    display: string;
}

interface DropdownChipProps {
    options: DropdownChipOption[];
    label: string;
    onSelect: (option: any) => void;
    colorMapping?: { [key: string]: string };
    selectable?: boolean;
    disabled?: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const DropdownChip: React.FC<DropdownChipProps> = ({ options, label, onSelect, colorMapping, selectable = false, disabled }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOption, setSelectedOption] = useState<string>(label);

    useEffect(() => { setSelectedOption(label) }, [label])

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (selectable) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (option: DropdownChipOption) => {
        setSelectedOption(option.display);
        onSelect(option.value);
        handleClose();
    };

    const chipColor = colorMapping && colorMapping[selectedOption] ? colorMapping[selectedOption] : 'default';

    return (
        <div>
            <Chip
                disabled={disabled}
                label={selectedOption}
                onClick={handleClick}
                deleteIcon={selectable ? <ArrowDropDownIcon /> : undefined}
                onDelete={selectable ? handleClick : undefined}
                style={{ backgroundColor: chipColor, width: 140 }}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    sx: {
                        animation: `${fadeIn} 0.3s ease-in-out`,
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleSelect(option)}>
                        {option.display}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default DropdownChip;