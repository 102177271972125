import { Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, styled, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PaymentChannel, PaymentEvent } from "../client/motal-api/generated";
import { PaymentStatusChip } from "../component/PaymentStatusChip";
import { PaymentTypeChip } from "../component/PaymentTypeChip";
// import UploadZone from "../component/LoanUploadZone";
import { useLoanQuery, usePaymentCreateMutation, usePaymentQuery, usePaymentUpdateMutation, useShopQuery } from "../client/query-client";
import { Location, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useRootContext } from "../context/RootContextProvider";
import toast from "react-hot-toast";
import RenderPaymentChannel from '../component/RenderPaymentChannel'
import PaymentUploadZone from "../component/PaymentUploadZone";

type PaymentDetailScreenProps = {
    mode: 'create' | 'update'
    location?: Location
};

const CustomInputLabel = styled(InputLabel)({
    position: "relative",
    transform: "none",
    marginBottom: "5px",
});

export const PaymentDetailScreen = (props: PaymentDetailScreenProps) => {

    const rootCtx = useRootContext()

    const nav = useNavigate();
    const { shopId, id, loanId } = useParams()
    useEffect(() => {
        console.log('id', id)
    }, [id])
    const paymentQuery = usePaymentQuery(id || '', props.mode === 'update')
    const loanQuery = useLoanQuery(paymentQuery.data?.loanId || loanId);
    const shopQuery = useShopQuery(paymentQuery.data?.shopId || shopId!);

    const { control, handleSubmit, reset, setValue, register, formState: { errors, isDirty } } = useForm<PaymentEvent>({
        defaultValues: {
            shopId: shopId,
            loanId: loanId,
            transactionScheduledAt: dayjs().format("YYYY-MM-DD"),
            transactionAt: dayjs().format("YYYY-MM-DD"),
            status: PaymentEvent.status.COMPLETED,
            type: PaymentEvent.type.REPAYMENT_FULL,
            paymentChannel: {
                type: PaymentChannel.type.PROMPT_PAY_QR
            },
            repaymentTermNumber: 1,
            paymentChannelRefId: ""
        },
        mode: 'all'
    });

    const updatePaymentMutation = usePaymentUpdateMutation()
    const createPaymentMutation = usePaymentCreateMutation()
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    useEffect(() => {
        if (paymentQuery.isFetched) {
            rootCtx.showLoading()
        } else {
            rootCtx.hideLoading()
        }
    }, [rootCtx, paymentQuery.isFetched]);

    useEffect(() => {
        if (!paymentQuery.isLoading
            && paymentQuery.data
            && props.mode === 'update') {
            reset(paymentQuery.data)
        }
    }, [
        paymentQuery.isLoading,
        paymentQuery.data,
        props.mode,
        reset
    ]);

    useEffect(() => {
        if (!loanQuery.isLoading
            && loanQuery.data
            && props.mode === 'create') {
            setValue('repaymentTermNumber', loanQuery.data.activeTermNumber);
        }
    }, [
        loanQuery.isLoading,
        loanQuery.data,
        props.mode,
        setValue
    ])

    const onSubmit = () => {
        handleSubmit((data) => {
            if (props.mode === 'update') {
                updatePaymentMutation.mutate({ payment: data, id: data.id! }, {
                    onSuccess: () => {
                        toast.success('บันทึกข้อมูลสำเร็จ')
                        nav(`/${shopId}/loan/${data.loanId}`);
                    }
                });
            } else {
                createPaymentMutation.mutate({ payment: data }, {
                    onSuccess: () => {
                        toast.success('บันทึกข้อมูลสำเร็จ')
                        nav(`/${shopId}/loan/${data.loanId}`);
                    }
                });
            }
        })()
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
                <Box sx={{ p: 2, mb: 16 }}>
                    <Typography variant="h5" p={2}>{props.mode === 'update' ? `รหัสชำระเงิน : ${paymentQuery.data?.id}` : 'ชำระเงิน'}</Typography>
                    <Card sx={{ my: 1, width: { xs: '100%', lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack direction={{ lg: 'row' }} gap={2}>
                                <FormControl fullWidth>
                                    <CustomInputLabel>วันที่รับชำระ</CustomInputLabel>
                                    <Controller control={control}
                                        name="transactionAt"
                                        render={({ field }) => (
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                value={field.value ? dayjs(field.value) : null}
                                                onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                        )} />
                                    {errors.transactionAt && <Typography color="error">{errors.transactionAt.message}</Typography>}
                                </FormControl>
                                <FormControl sx={{ width: 140 }}>
                                    <CustomInputLabel>งวดที่</CustomInputLabel>
                                    <Stack direction='row'>
                                        <Controller
                                            control={control}
                                            name='repaymentTermNumber'
                                            render={({ field }) => (
                                                <Typography mt={1}>{`${field.value ? field.value : "-"} / `}</Typography>
                                            )}
                                        />
                                        <Typography mt={1}>{loanQuery.data?.repaymentTermCount}</Typography>
                                    </Stack>

                                </FormControl>
                                <Stack>
                                    <CustomInputLabel>สถานะ</CustomInputLabel>
                                    <Box sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="status"
                                            render={({ field }) => (
                                                <PaymentStatusChip selectable={
                                                    props.mode === 'create'
                                                    || loanQuery.data?.activeTermNumber === 1
                                                    || paymentQuery.data?.repaymentTermNumber! >= (loanQuery.data?.activeTermNumber! - 1)
                                                } status={field.value!} onSelect={field.onChange} />
                                            )} />
                                    </Box>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" >
                        <Card sx={{ my: 1, width: { xs: '100%', lg: '49%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack direction='column' gap={2}>
                                    <Typography variant="h6">ข้อมูลการชำระเงิน</Typography>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <Stack>
                                            <CustomInputLabel>ประเภท</CustomInputLabel>
                                            <Box sx={{ pt: 0.5 }}>
                                                <Controller
                                                    control={control}
                                                    name="type"
                                                    render={({ field }) => (
                                                        <PaymentTypeChip selectable={props.mode === 'create'} type={field.value!} onSelect={field.onChange} />
                                                    )} />
                                            </Box>
                                        </Stack>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>จำนวนเงิน</CustomInputLabel>
                                            <Controller
                                                name="amount"
                                                control={control}
                                                rules={{
                                                    required: 'ต้องการจำนวนเงิน',
                                                    min: { value: 0, message: 'เป็นตัวเลขบวกเท่านั้น' }
                                                }}
                                                render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                            />
                                            {errors.amount && <Typography color="error">{errors.amount.message}</Typography>}
                                        </FormControl>
                                    </Stack>

                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>ช่องทางรับชำระ</CustomInputLabel>
                                            <Controller control={control} name="paymentChannelRefId" render={({ field }) => (
                                                <Select
                                                    labelId="paymentChannel-label"
                                                    id="paymentChannel-id"
                                                    size='small'
                                                    readOnly={
                                                        !(props.mode === 'create'
                                                            || loanQuery.data?.activeTermNumber === 1
                                                            || paymentQuery.data?.repaymentTermNumber! >= (loanQuery.data?.activeTermNumber! - 1))
                                                    }


                                                    {...field}
                                                >
                                                    {shopQuery.data?.paymentChannels?.filter(p => p.refId === field.value)
                                                        .map(p => {
                                                            return (
                                                                <MenuItem value={p.refId}><Box width='100%'><RenderPaymentChannel paymentChannel={p} hideIcon /></Box></MenuItem>
                                                            )
                                                        })}
                                                    {shopQuery.data?.paymentChannels?.filter(p => p.status === PaymentChannel.status.ACTIVE && p.refId !== field.value)
                                                        .map(p => {
                                                            return (
                                                                <MenuItem value={p.refId}><Box width='100%'><RenderPaymentChannel paymentChannel={p} hideIcon /></Box></MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            )} />
                                        </FormControl>
                                    </Stack>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>โน้ต</CustomInputLabel>
                                        <TextField {...register('note')} size='small' variant="outlined" />
                                    </FormControl>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{ my: 1, width: { xs: '100%', lg: '30%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack direction='column' gap={2}>
                                    <Typography variant="h6">ข้อมูลสินเชื่อ</Typography>
                                    <Stack gap={3}>
                                        <Stack gap={1}>
                                            <Typography color={'#9e9e9e'}>หมายเลขเคส</Typography>
                                            <Typography variant="body2" letterSpacing={1}>{loanQuery.data?.referenceId}</Typography>
                                        </Stack>
                                        <Stack gap={1}>
                                            <Typography color={'#9e9e9e'}>ยอดรวม</Typography>
                                            <Typography variant="body2" letterSpacing={1}>{currencyFormat.format(loanQuery.data?.totalAmount!)}</Typography>
                                        </Stack>
                                        <Stack gap={1}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography color={'#9e9e9e'}>เหลือทั้งหมด</Typography>
                                                    <Typography variant="body2" letterSpacing={1}>{currencyFormat.format(loanQuery.data?.totalRemainingAmount!)}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color={'#9e9e9e'}>เหลือในงวด</Typography>
                                                    <Typography variant="body2" letterSpacing={1}>{currencyFormat.format(loanQuery.data?.activeTermRemainingAmount!)}</Typography>
                                                </Grid>

                                            </Grid>
                                        </Stack>
                                        <Stack gap={1}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography color={'#9e9e9e'}>ค่าปรับทั้งหมด</Typography>
                                                    <Typography variant="body2" letterSpacing={1}>{currencyFormat.format(loanQuery.data?.totalFineAmount!)}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color={'#9e9e9e'}>ค่าปรับในงวด</Typography>
                                                    <Typography variant="body2" letterSpacing={1}>{currencyFormat.format(loanQuery.data?.activeTermFineAmount!)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                    <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" >
                        <Card sx={{ my: 1, width: { xs: '100%', lg: '49%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack direction='column' gap={2}>
                                    <Typography variant="h6">เพิ่มเติม</Typography>

                                    <CustomInputLabel>ไฟล์หลักฐาน</CustomInputLabel>
                                    <Controller
                                        control={control}
                                        name="files"
                                        render={({ field }) => {
                                            return (<PaymentUploadZone paymentId={id!} initialFiles={field.value || []} onFilesChange={field.onChange} />)
                                        }}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{ my: 1, width: { xs: '100%', lg: '30%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack direction='column' gap={2}>
                                    <Typography variant="h6">ข้อมูลลูกค้า</Typography>
                                    <Stack gap={3}>
                                        <Stack gap={1}>
                                            <Typography color={'#9e9e9e'}>ชื่อ-นามสกุล</Typography>
                                            <Typography variant="body2" letterSpacing={1}>{`${loanQuery.data?.customer?.firstName} ${loanQuery.data?.customer?.lastName}`}</Typography>
                                        </Stack>
                                        <Stack gap={1}>
                                            <Typography color={'#9e9e9e'}>เบอร์โทรศัพท์</Typography>
                                            <Typography variant="body2" letterSpacing={1}>{loanQuery.data?.customer?.phoneNumber}</Typography>
                                        </Stack>
                                        <Stack gap={1}>
                                            <Typography color={'#9e9e9e'}>อีเมล</Typography>
                                            <Typography variant="body2" letterSpacing={1}>{loanQuery.data?.customer?.email}</Typography>
                                        </Stack>
                                    </Stack>

                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>

                </Box>
            </LocalizationProvider >
            <Box sx={{ visibility: props.mode === 'create' || (props.mode === 'update' && isDirty) ? 'visible' : 'hidden', p: 2, bgcolor: 'white', width: { xs: '100%', sm: 'calc(100% - 240px)' }, position: 'fixed', bottom: 0, zIndex: 99 }} >
                <Stack direction='row' gap={1} justifyContent='center'>
                    <Button variant='outlined' size='large'
                        onClick={() => nav(`/${shopId}/loan/${paymentQuery.data ? paymentQuery.data.loanId! : loanId}`)}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>ยกเลิก</Button>
                    <Button variant='contained' size='large'
                        onClick={onSubmit}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>บันทึก</Button>
                </Stack>
            </Box>
        </>
    );
};
