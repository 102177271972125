import { useAuth, useClerk } from "@clerk/clerk-react";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { useUserProfileMutation } from "../client/query-client";
import { Backdrop, CircularProgress } from "@mui/material";
import { User } from "../client/motal-api/generated";

type RootContextType = {
    user: User,
    loading: boolean,
    showLoading: () => void,
    hideLoading: () => void,
}

const RootContext = createContext<RootContextType>({
    user: {},
    loading: false,
    showLoading: () => { },
    hideLoading: () => { },
});

type RootContextProviderProps = {
    children: ReactNode
}

export const RootContextProvider = ({ children }: RootContextProviderProps) => {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState<User>({})

    const showLoading = () => setLoading(true);
    const hideLoading = () => setLoading(false);

    const { isLoaded, isSignedIn } = useAuth();
    const clerk = useClerk();
    const userProfileMutation = useUserProfileMutation();

    useMemo(() => {
        if (isLoaded && isSignedIn) {
            userProfileMutation.mutateAsync()
                .then(u => setUser(u));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn])

    if (isLoaded && !isSignedIn) {
        clerk.openSignIn()
    }

    return (
        <RootContext.Provider value={{
            user,
            loading,
            showLoading,
            hideLoading
        }}>
            {children}
            <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    color: '#fff',
                }}
                open={loading && !isLoaded}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </RootContext.Provider>
    )
}

export const useRootContext = () => useContext(RootContext);