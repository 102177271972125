
export function dayDiff(
    dateStart: Date,
    dateEnd: Date | string = new Date(),
): number {
    if (typeof dateEnd === 'string')
        dateEnd = new Date();

    let delta: number = Math.abs(dateStart.getTime() - dateEnd.getTime());
    return Math.ceil(delta / (1000 * 3600 * 24));
}