/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type User = {
    id?: string;
    clerkUserId?: string;
    primaryEmail?: string;
    phoneNumber?: string;
    nationalId?: string;
    firstName?: string;
    laseName?: string;
    systemRole?: User.systemRole;
    status?: User.status;
    lastLoginAt?: string;
    createdAt?: string;
    updatedAt?: string;
};
export namespace User {
    export enum systemRole {
        ADMIN = 'ADMIN',
        MEMBER = 'MEMBER',
    }
    export enum status {
        PENDING = 'PENDING',
        ACTIVE = 'ACTIVE',
        SUSPENDED = 'SUSPENDED',
    }
}

