/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentChannel = {
    refId?: string;
    type?: PaymentChannel.type;
    status?: PaymentChannel.status;
    bankCode?: PaymentChannel.bankCode;
    accountName?: string;
    accountId?: string;
    qrCodeImageFile?: string;
    qrCodeImageUrl?: string;
};
export namespace PaymentChannel {
    export enum type {
        PROMPT_PAY = 'PROMPT_PAY',
        PROMPT_PAY_QR = 'PROMPT_PAY_QR',
        BANK_ACCOUNT = 'BANK_ACCOUNT',
    }
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }
    export enum bankCode {
        BBL = 'BBL',
        BAY = 'BAY',
        SCB = 'SCB',
        KBANK = 'KBANK',
        KTB = 'KTB',
        TTB = 'TTB',
    }
}

