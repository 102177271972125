/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessExceptionResponse = {
    code?: BusinessExceptionResponse.code;
    message?: string;
};
export namespace BusinessExceptionResponse {
    export enum code {
        USER_NOT_FOUND = 'USER_NOT_FOUND',
        MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND',
        ACCESS_DENIED = 'ACCESS_DENIED',
        API_CALL_ERROR = 'API_CALL_ERROR',
        UNHANDLED = 'UNHANDLED',
    }
}

