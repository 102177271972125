
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePaymentQueryByShopId } from '../client/query-client';
import SearchIcon from '@mui/icons-material/Search';
import { PaymentEvent } from '../client/motal-api/generated';
import { useRootContext } from '../context/RootContextProvider';
import { PaymentStatusChip } from '../component/PaymentStatusChip';
import dayjs from 'dayjs';
import { PaymentTypeChip } from '../component/PaymentTypeChip';


export const PaymentListScreen = () => {
    const nav = useNavigate();
    const location = useLocation();
    const { shopId } = useParams();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 1, //customize the default page size
    });
    const rootCtx = useRootContext();
    // const loansQuery = useLoansQuery(shopId || '',);
    // const usePaymentQueryInitial = usePaymentQueryByShopId(shopId || '', 1)
    const usePaymentQueryPaginage = usePaymentQueryByShopId(shopId || '', pagination.pageIndex + 1)

    // useEffect(() => {
    //     console.log('pagination', pagination)
    // }, [pagination])
    useEffect(() => {
        if (usePaymentQueryPaginage.status === 'success') {
            setPagination({
                pageIndex: 0,
                pageSize: usePaymentQueryPaginage.data.pagination?.pageSize || 1
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usePaymentQueryPaginage.status])

    useEffect(() => {
        if (usePaymentQueryPaginage.isLoading) {
            rootCtx.showLoading()
        } else {
            rootCtx.hideLoading()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usePaymentQueryPaginage.isLoading])

    const columns = useMemo(
        () => [
            {
                accessorFn: (row: PaymentEvent) => `${dayjs(row.transactionScheduledAt).format('DD/MM/YYYY')}`, //simple recommended way to define a column
                header: 'วันที่กำหนดชำระ',
                enableHiding: false,
                enablePinning: false,
            },
            {
                accessorFn: (row: PaymentEvent) => `${row.transactionAt ? dayjs(row.transactionAt).format('DD/MM/YYYY') : '-'}`, //simple recommended way to define a column
                header: 'วันที่รับชำระ',
                enableHiding: false,
                enablePinning: false,
            },
            {
                accessorFn: (row: PaymentEvent) => `${row.amount?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, //simple recommended way to define a column
                header: 'จำนวนเงิน',
                enableHiding: false,
                enablePinning: false,
            },
            {
                accessorFn: (row: PaymentEvent) => <PaymentTypeChip type={row.type!} />, //simple recommended way to define a column
                header: 'ประเภท',
                enableHiding: false,
                enablePinning: false,
            },
            {
                accessorFn: (row: PaymentEvent) => <PaymentStatusChip status={row.status!} />,
                header: 'สถานะ',
                enableHiding: false,
                enablePinning: false,
            },
            {
                accessorFn: (row: PaymentEvent) => <div onClick={() => nav(`/${shopId}/loan/${row.loanId}`)}>{`${row.loanId || ''}`}</div>,
                header: 'สินเชื่อ',
                enableHiding: false,
                enablePinning: false,
            },

        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const table = useMaterialReactTable({
        columns: columns,
        data: usePaymentQueryPaginage.data?.content || [],
        enableColumnPinning: true,
        enableGlobalFilter: true,
        enableColumnFilters: false,
        enableSorting: false,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            shape: 'rounded',
            page: pagination.pageIndex + 1,
            defaultPage: 0,

            showRowsPerPage: false
        },
        rowCount: usePaymentQueryPaginage.data?.pagination?.totalElements || 1,
        onPaginationChange: setPagination,
        manualPagination: true,
        autoResetPageIndex: false,

        initialState: {
            showGlobalFilter: true,
            columnPinning: {
                right: ['mrt-row-actions'],
            },
        },
        pageCount: usePaymentQueryPaginage.data?.pagination?.totalPages || 1,
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: (action) => (
            <IconButton onClick={() => nav(`${location.pathname}/${action.row.original.id}`)}>
                <ArrowForwardIosIcon />
            </IconButton>
        ),
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },

    })

    return (
        <Box p={2}>
            <Stack gap={2} sx={{ maxWidth: '90vw' }}>
                <Stack direction='row' gap={2} justifyContent='space-between'>
                    <TextField
                        sx={{ backgroundColor: 'white', width: '49%' }}
                        variant="outlined"
                        placeholder="ค้าหา รหัสอ้างอิง, ชื่อ-นามสกุล, เลขบัตร, IMEI, Serial Number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Button variant='contained' size='medium' onClick={() => nav(`${location.pathname}/create`)}>เพิ่มการชำระเงิน</Button>
                </Stack>
                <Box sx={{ maxWidth: '100%' }}>
                    <MaterialReactTable table={table} />
                </Box>
            </Stack >
        </Box>
    )
}