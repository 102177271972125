import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMotalClient } from '../client/motal-api';
import { PaymentPreviewFileCard } from './PaymentPreviewFileCard';

interface FileWithPreview {
    file: File;
    uploaded: boolean;
}

type PaymentUploadZoneProps = {
    paymentId: string,
    initialFiles?: string[],
    onFilesChange?: (files: string[]) => void,
}

const PaymentUploadZone: React.FC<PaymentUploadZoneProps> = ({ paymentId, initialFiles = [], onFilesChange }) => {
    const client = useMotalClient();
    const [files, setFiles] = useState<FileWithPreview[]>([]);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    // const [showSuccessIcon, setShowSuccessIcon] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        if (onFilesChange) {
            onFilesChange(
                initialFiles.concat(
                    files.map(f => f.file.name)
                        .filter((fileName) => initialFiles.indexOf(fileName) < 0))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, onFilesChange])

    const onDrop = (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.filter(
            (file) => !files.some((existingFile) => existingFile.file.name === file.name)
        ).map((file) => ({
            file,
            uploaded: false,
        }));

        const updatedFiles = [...files, ...newFiles];
        setFiles(updatedFiles);
        newFiles.forEach(uploadFile);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const uploadFile = async ({ file }: FileWithPreview) => { 
        try {
            const response = await client.payment.getPaymentUploadUrl({ paymentId: paymentId, fileName: file.name });
            const { url } = response;

            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url!, true);
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.onload = () => {
                if (xhr.status === 200) {
                    setFiles((prevFiles) =>
                        prevFiles.map((f) =>
                            f.file.name === file.name ? { ...f, uploaded: true } : f
                        )
                    );
                    // setShowSuccessIcon((prev) => ({ ...prev, [file.name]: true }));
                    // setTimeout(() => {
                    //     setShowSuccessIcon((prev) => ({ ...prev, [file.name]: false }));
                    // }, 500);
                } else {
                    console.error('Upload failed', xhr.responseText);
                }
            };

            xhr.onerror = () => {
                console.error('Upload error', xhr.responseText);
            };

            xhr.send(file);
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    const onDownload = async (fileName: string) => {
        try {
            const response = await client.payment.getPaymentDownloadUrl({ paymentId: paymentId, fileName: fileName });
            const { url } = response;
            const blobResponse = await fetch(url!);
            if (blobResponse.ok) {
                const blob = await blobResponse.blob();
                saveAs(blob, fileName);
            }
        } catch (error) {
            console.error('Error downloading file', error);
        }
    }

    const handleDelete = async () => {
        if (selectedFile) {
            try {
                if (onFilesChange) {
                    onFilesChange(
                        initialFiles.concat(
                            files.map(f => f.file.name)
                                .filter((fileName) => initialFiles.indexOf(fileName) < 0))
                            .filter((fileName) => fileName !== selectedFile)
                    )
                }
                handleClose();
            } catch (error) {
                console.error('Error deleting file', error);
            }
        }
    };

    const handleClickOpen = (fileName: string) => {
        setSelectedFile(fileName);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFile(null);
    };

    return (
        <>
            <Box
                {...getRootProps()}
                sx={{
                    border: '2px solid darkgray',
                    borderRadius: '8px',
                    backgroundColor: '#e0e0e0',
                    padding: 4,
                    textAlign: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#b3e5fc',
                        color: '#03a9f4',
                        borderColor: '#03a9f4',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '20px'
                }}
            >
                <input {...getInputProps()} />
                <Stack direction='row' gap={1}>
                    <FileUploadIcon />
                    <Typography>อัพโหลดไฟล์</Typography>
                </Stack>
            </Box>

            <Box gap={2} sx={{display:'flex',flexWrap:'wrap'}}>
                {
                    initialFiles.length > 0
                        ? initialFiles.map((fileName) => (
                            <PaymentPreviewFileCard file={fileName} paymentId={paymentId} onDelete={() => handleClickOpen(fileName)} onDownload={() => onDownload(fileName)} />
                            // <Paper key={fileName} sx={{ py: 1, px: 2 }}>
                            //     <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            //         <Stack direction='row' gap={1} >
                            //             <Typography>{fileName}</Typography>
                            //             {files.find(f => f.file.name === fileName)?.uploaded && (
                            //                 <CheckCircleIcon
                            //                     color="success"
                            //                     sx={{
                            //                         opacity: showSuccessIcon[fileName] ? 1 : 0,
                            //                         transition: 'opacity 1s ease-out'
                            //                     }}
                            //                 />
                            //             )}
                            //         </Stack>
                            //         <Stack direction='row'>
                            //             <IconButton onClick={() => onDownload(fileName)}><FileDownloadIcon /></IconButton>
                            //             <IconButton onClick={() => handleClickOpen(fileName)}><DeleteForeverIcon /></IconButton>
                            //         </Stack>
                            //     </Stack>
                            // </Paper>
                        ))
                        : <Box width='100%' mt={2}><Typography variant='body2' textAlign='center'>ยังไม่มีไฟล์</Typography></Box>
                }
            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>ลบไฟล์</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        คุณต้องการลบไฟล์ {selectedFile}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack direction='row' justifyContent='space-between' gap={1} p={1}>
                        <Button variant='outlined' onClick={handleClose}>ยกเลิก</Button>
                        <Button variant='outlined' onClick={handleDelete} color="error">ลบ</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PaymentUploadZone;
