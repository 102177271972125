/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopMemberInvitationRequest = {
    emailAddress?: string;
    role?: ShopMemberInvitationRequest.role;
};
export namespace ShopMemberInvitationRequest {
    export enum role {
        OWNER = 'OWNER',
        STAFF = 'STAFF',
    }
}

