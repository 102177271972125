import { useAuth } from "@clerk/clerk-react";
import { MotalClient } from "./generated";

export const useMotalClient = () => {
    const auth = useAuth();
    return new MotalClient({
        BASE: process.env.REACT_APP_MOTAL_API_URL,
        TOKEN: async () => {
            const token = await auth.getToken({ template: "default" });
            // console.log('default token', token)
            return token || ""; // Return an empty string if the token is null
        }
    })
}

