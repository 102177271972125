import AddIcon from '@mui/icons-material/Add';
import { Box, Card, CardActionArea, CardContent, CircularProgress, Container, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useShopsQuery } from "../client/query-client";
import { Shop } from '../client/motal-api/generated';



export const HomeScreen = () => {

    const nav = useNavigate()
    const shopQuery = useShopsQuery()

    // const currentDate = new Date()

    return (
        <>
            {
                shopQuery.isLoading ? <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /> </Box> :

                    <Container sx={{
                        padding: {
                            xs: 0, sm: 0
                        }
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignContent: 'flex-start',
                                justifyContent: {
                                    xs: 'center', md: 'flex-start'
                                },
                                p: {
                                    xs: 0, sm: 0, md: 1
                                },
                                m: {
                                    xs: 0, sm: 0, md: 1
                                },
                                height: 200,
                                borderRadius: 1,
                            }}
                        >


                            {shopQuery.data?.map((shop) => {
                                return (
                                    <Box sx={{
                                        p: 1,
                                        m: 1,
                                        borderColor: (theme) =>
                                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                        fontSize: '0.875rem',
                                        fontWeight: '700',
                                        maxWidth: '100%'
                                    }}>
                                        <Card sx={{ maxWidth: '100%', width: 325 }}>
                                            <CardActionArea onClick={() => nav(`/${shop.id}`)}>
                                                <CardContent>
                                                    <Box sx={{ flexGrow: 1, minHeight: 160 }} alignContent='center'>
                                                        <Stack direction='column' gap={1} alignItems='center'>
                                                            <Typography variant="body2"> {`ร้าน`}</Typography>
                                                            <Typography variant="h6"> {`${shop.name}`}</Typography>
                                                        </Stack>
                                                    </Box>
                                                    <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                                                    <Typography variant="body2" align='center'>{
                                                        shop.status === Shop.status.ACTIVE
                                                            ? `พร้อมใช้งาน`
                                                            : shop.status === Shop.status.WAITING_FOR_APPROVE
                                                                ? 'รออนุมัติ'
                                                                : 'ติดต่ออะตอมเทค'
                                                    }</Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                )
                            })}

                            <Box sx={{
                                p: 1,
                                m: 1,
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                fontSize: '0.875rem',
                                fontWeight: '700',
                                maxWidth: '100%'
                            }}>
                                <Card sx={{ maxWidth: '100%', width: 325 }}>
                                    <CardActionArea onClick={() => nav(`/register-shop`)}>
                                        <CardContent>
                                            <Stack>
                                                <Box sx={{ flexGrow: 1, minHeight: 160 }} alignContent='center'>
                                                    <Stack direction='column' gap={1} alignItems='center'>
                                                        <AddIcon fontSize="large" />
                                                        <Typography> สร้างร้านค้า</Typography>
                                                    </Stack>
                                                </Box>
                                                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                                                <Typography variant="body2" align='center'>ส่งคำขอเพิ่มร้านค้าเพื่อเข้าใช้งาน</Typography>
                                            </Stack>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Box>

                        </Box>
                    </Container >
            }
        </>
    )
}