import { useEffect, useState } from "react"
import { PaymentEvent } from "../client/motal-api/generated"
import DropdownChip from "./DropDownChip"

type PaymentTypeChipProps = {
    type: PaymentEvent.type
    selectable?: boolean
    onSelect?: (option: PaymentEvent.type) => void;
    disabled?: boolean;
}

export const PaymentTypeChip: React.FC<PaymentTypeChipProps> = ({ disabled, type, selectable = false, onSelect = () => { } }) => {

    const [selectedOption, setSelectedOption] = useState<PaymentEvent.type>(type);

    useEffect(() => {
        setSelectedOption(type)
    }, [type])

    const handleSelect = (option: PaymentEvent.type) => {
        setSelectedOption(option);
        onSelect(option);
    }

    const options = [
        { value: PaymentEvent.type.DOWN_PAYMENT, display: 'เงินดาวน์' },
        { value: PaymentEvent.type.REPAYMENT_FULL, display: 'ปิดค่างวด' },
        { value: PaymentEvent.type.REPAYMENT_PARTIAL, display: 'แบ่งจ่ายค่างวด' },
        { value: PaymentEvent.type.CLOSED, display: 'ปิดยอดทั้งหมด' },
        { value: PaymentEvent.type.FINE, display: 'ค่าปรับ' },
    ];

    const colorMapping = {
        'เงินดาวน์': '#bbdefb',
        'ปิดค่างวด': '#bbdefb',
        'แบ่งจ่ายค่างวด': '#bbdefb',
        'ปิดยอดทั้งหมด': '#aed581',
        'ค่าปรับ': '#ef9a9a',
    };

    return (
        <DropdownChip
            disabled={disabled}
            label={options.find(o => o.value === selectedOption)!.display}
            options={options}
            onSelect={handleSelect}
            colorMapping={colorMapping}
            selectable={selectable}
        />
    )

}