import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { PaymentChannel } from "../../client/motal-api/generated";
import { Delete } from "@mui/icons-material";
type Props = {
    paymentChannel: PaymentChannel
    onDelete?: () => void
}
type BankConfig = {
    bankCode: PaymentChannel.bankCode
    logo: string
    color: string
}

const bankConfigs: BankConfig[] = [
    { bankCode: PaymentChannel.bankCode.BBL, logo: '/bank-logo/bbl.svg', color: '#00529f' },
    { bankCode: PaymentChannel.bankCode.BAY, logo: '/bank-logo/bay.svg', color: '#fec43b' },
    { bankCode: PaymentChannel.bankCode.SCB, logo: '/bank-logo/scb.svg', color: '#4e2e7f' },
    { bankCode: PaymentChannel.bankCode.KBANK, logo: '/bank-logo/kbank.svg', color: '#138f2d' },
    { bankCode: PaymentChannel.bankCode.KTB, logo: '/bank-logo/ktb.svg', color: '#1ba5e1' },
    { bankCode: PaymentChannel.bankCode.TTB, logo: '/bank-logo/ttb.svg', color: '#ecf0f1' },

]
const BankPaymentMethod = (props: Props) => {
    const bankConfig = bankConfigs.find(bankConfig => bankConfig.bankCode === props.paymentChannel.bankCode)
    return (
        <Box sx={{ border: '2px solid #eeeeee', borderRadius: 2, p: 1, backgroundColor: bankConfig?.color }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' gap={1} alignItems='center'>
                    <Box p={1}>
                        <img width={28} src={bankConfig?.logo} alt='' />
                    </Box>
                    <Stack justifyContent='flex-start' alignItems='flex-start'>
                        <Typography color='white' sx={{ textShadow: '1px 1px 1px #263238' }}>{props.paymentChannel.accountId}</Typography>
                        <Typography variant='body2' color='white' sx={{ textShadow: '1px 1px 1px #263238' }}>{props.paymentChannel.accountName || '-'}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row'>
                    {props.onDelete && <IconButton
                        sx={{ color: 'white' }}
                        onClick={() => {
                            if (props.onDelete)
                                props.onDelete()
                        }}>
                        {/* <ContentCopyIcon sx={{ color: 'white' }} /> */}
                        <Delete />
                    </IconButton>}
                    {/* <IconButton onClick={() => { }}>
                        <ArrowForwardIosIcon sx={{ color: 'white' }} />
                    </IconButton> */}
                    {props.paymentChannel.status === PaymentChannel.status.INACTIVE && <Chip label="เลิกใช้งาน" variant="outlined" sx={{ borderRadius: '3px', marginRight: '3px', color: 'white', backgroundColor: 'gray' }} />}
                </Stack>
            </Stack>
        </Box>
    );
}
export default BankPaymentMethod