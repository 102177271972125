/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Device } from './Device';
import type { RepaymentTerm } from './RepaymentTerm';
export type Loan = {
    id?: string;
    transactionDate?: string;
    sequenceNumber?: number;
    referenceId?: string;
    shopId?: string;
    customerId?: string;
    device?: Device;
    status?: Loan.status;
    loanFundSource?: Loan.loanFundSource;
    activeTermNumber?: number;
    activeTermScheduledDate?: string;
    commissionAmount?: number;
    costAmount?: number;
    repaymentTerms?: Array<RepaymentTerm>;
    files?: Array<string>;
    totalAmount?: number;
    downPaymentAmount?: number;
    downPaymentDate?: string;
    repaymentTotalAmount?: number;
    repaymentTermCount?: number;
    repaymentTermAmount?: number;
    firstRepaymentDate?: string;
    repaymentTermFrequency?: number;
    repaymentTermFrequencyType?: Loan.repaymentTermFrequencyType;
    createdBy?: string;
    createdAt?: string;
    updatedAt?: string;
    termCalculatedAt?: string;
};
export namespace Loan {
    export enum status {
        NORMAL = 'NORMAL',
        CLOSED = 'CLOSED',
        REFUND = 'REFUND',
        FOLLOWING_UP = 'FOLLOWING_UP',
        CANNOT_CONTACT_CUSTOMER = 'CANNOT_CONTACT_CUSTOMER',
        NON_PERFORMING = 'NON_PERFORMING',
        REPORTED = 'REPORTED',
        CANCEL = 'CANCEL',
    }
    export enum loanFundSource {
        SHOP = 'SHOP',
        FINANCE = 'FINANCE',
    }
    export enum repaymentTermFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

