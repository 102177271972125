/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Device = {
    usedStatus?: Device.usedStatus;
    brand?: string;
    model?: string;
    color?: string;
    description?: string;
    imei?: string;
    serialNumber?: string;
};
export namespace Device {
    export enum usedStatus {
        NEW = 'NEW',
        USED = 'USED',
    }
}

