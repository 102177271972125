/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from './Customer';
import type { Device } from './Device';
import type { Shop } from './Shop';
export type LoanEvent = {
    id?: string;
    transactionDate: string;
    sequenceNumber?: number;
    referenceId: string;
    shopId?: string;
    shop?: Shop;
    shopPrimaryImageUrl?: string;
    customer?: Customer;
    device?: Device;
    activeTermNumber?: number;
    activeTermScheduledDate?: string;
    activeTermRemainingAmount?: number;
    activeTermFineAmount?: number;
    commissionAmount?: number;
    costAmount?: number;
    upcomingPaymentDays?: number;
    status?: LoanEvent.status;
    loanFundSource: LoanEvent.loanFundSource;
    totalAmount: number;
    totalRemainingAmount?: number;
    totalFineAmount?: number;
    totalPaidAmount?: number;
    totalProfit?: number;
    files?: Array<string>;
    downPaymentAmount: number;
    downPaymentDate?: string;
    repaymentTotalAmount: number;
    repaymentTermCount: number;
    repaymentTermAmount: number;
    firstRepaymentDate?: string;
    repaymentTermFrequency?: number;
    repaymentTermFrequencyType?: LoanEvent.repaymentTermFrequencyType;
    createdBy?: string;
    createdAt?: string;
    updatedAt?: string;
};
export namespace LoanEvent {
    export enum status {
        NORMAL = 'NORMAL',
        CLOSED = 'CLOSED',
        REFUND = 'REFUND',
        FOLLOWING_UP = 'FOLLOWING_UP',
        CANNOT_CONTACT_CUSTOMER = 'CANNOT_CONTACT_CUSTOMER',
        NON_PERFORMING = 'NON_PERFORMING',
        REPORTED = 'REPORTED',
        CANCEL = 'CANCEL',
    }
    export enum loanFundSource {
        SHOP = 'SHOP',
        FINANCE = 'FINANCE',
    }
    export enum repaymentTermFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

