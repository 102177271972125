import { Box, Card, CardContent, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useShopMonthlySummaryQuery, useShopYearlySummaryQuery } from "../client/query-client";
import { OwnerCheck } from "../component/OwnerCheck";




export const ShopDashboardScreen = () => {
    const [year, setYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState<number>(dayjs().get('month') + 1)
    const { shopId } = useParams()
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const shopMonthlySummaryQuery = useShopMonthlySummaryQuery(shopId!, year, selectedMonth)
    const shopYearlySummaryQuery = useShopYearlySummaryQuery(shopId!, year)

    return (
        <OwnerCheck>
            <Box p={2}>
                <Box sx={{ width: { xs: '90%', md: '40%' }, mb: 2 }}>
                    <Stack direction={{ md: 'row' }} gap={2} >
                        <TextField
                            sx={{ backgroundColor: 'white' }}
                            label="ปี"
                            value={year}
                            onChange={(e) => setYear(parseInt(e.target.value))}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                        <>
                            <FormControl sx={{ width: '300px' }} fullWidth>
                                <InputLabel id="demo-simple-select-label">เดือน</InputLabel>
                                <Select
                                    sx={{ backgroundColor: 'white' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value as number)}
                                    label="เดือน"
                                // onChange={handleChange}
                                >
                                    <MenuItem value={1}>มกราคม</MenuItem>
                                    <MenuItem value={2}>กุมภาพันธ์</MenuItem>
                                    <MenuItem value={3}>มีนาคม</MenuItem>
                                    <MenuItem value={4}>เมษายน</MenuItem>
                                    <MenuItem value={5}>พฤษภาคม</MenuItem>
                                    <MenuItem value={6}>มิถุนายน</MenuItem>
                                    <MenuItem value={7}>กรกฎาคม</MenuItem>
                                    <MenuItem value={8}>สิงหาคม</MenuItem>
                                    <MenuItem value={9}>กันยายน</MenuItem>
                                    <MenuItem value={10}>ตุลาคม</MenuItem>
                                    <MenuItem value={11}>พฤศจิกายน</MenuItem>
                                    <MenuItem value={12}>ธันวาคม</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    </Stack>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Typography color='#9e9e9e' component="div" sx={{ py: 2, fontSize: 20 }}>
                        สรุปรายเดือน
                    </Typography>

                    <Stack direction={{ lg: 'row' }} sx={{ pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                        <Card sx={{ width: { xs: '100%', lg: '31%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            จำนวนสินเชื่อใหม่
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div" >
                                            {shopMonthlySummaryQuery.data?.loanCount}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { lg: '33%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            ค่างวดเรียกเก็บ
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div" color='#9e9e9e'>
                                            {currencyFormat.format(shopMonthlySummaryQuery.data?.totalScheduledAmount || 0)}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>

                        <Card sx={{ width: { lg: '33%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack alignContent='stretch' justifyContent='space-evenly'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="body1" component="div">
                                            ค่างวดเก็บได้จริง
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                        <Typography variant="h2" component="div" color='#aed581'>
                                            {currencyFormat.format(shopMonthlySummaryQuery.data?.totalRepaymentAmount || 0)}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>

                    </Stack>

                    <Stack direction={{ lg: 'row' }} sx={{ width: '100%', pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                        <Card sx={{ width: { lg: '49%' } }}>
                            {//TODO: on click go to loan
                            }
                            <CardContent sx={{ padding: 2 }}>
                                {shopMonthlySummaryQuery.isFetched
                                    ?
                                    <Stack alignContent='stretch' justifyContent='space-evenly'>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Typography variant="body1" component="div">
                                                {`รายได้ (${shopMonthlySummaryQuery.data?.transactionCount || 0} รายการ)`}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Typography variant="h2" component="div">
                                                {currencyFormat.format(shopMonthlySummaryQuery.data?.totalAmount || 0)}
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ my: 1 }} />
                                        <Stack direction='row' justifyContent='space-evenly'>
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div">
                                                        {`เงินดาว (${shopMonthlySummaryQuery.data?.downPaymentTransactionCount || 0} รายการ)`}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#9e9e9e'>
                                                    {currencyFormat.format(shopMonthlySummaryQuery.data?.totalDownPaymentAmount || 0)}
                                                </Typography>
                                            </Stack>
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div">
                                                        {`ค่างวด (${shopMonthlySummaryQuery.data?.repaymentTransactionCount || 0} รายการ)`}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#aed581'>
                                                    {currencyFormat.format(shopMonthlySummaryQuery.data?.totalRepaymentAmount || 0)}
                                                </Typography>
                                            </Stack>
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div" >
                                                        {`ค่าปรับ (${shopMonthlySummaryQuery.data?.finePaymentTransactionCount || 0} รายการ)`}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#03a9f4'>
                                                    {currencyFormat.format(shopMonthlySummaryQuery.data?.totalFinePaymentAmount || 0)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    : <></>
                                }

                            </CardContent>
                        </Card>

                        <Card sx={{ width: { lg: '49%' } }}>
                            {//TODO: on click go to loan
                            }
                            <CardContent sx={{ padding: 2 }}>
                                {shopMonthlySummaryQuery.isFetched
                                    ?
                                    <Stack alignContent='stretch' justifyContent='space-evenly'>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Typography variant="body1" component="div">
                                                รายจ่าย
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Typography variant="h2" component="div" color='#ef9a9a'>
                                                {currencyFormat.format(shopMonthlySummaryQuery.data?.totalCostAmount || 0)}
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ my: 1 }} />
                                        <Stack direction='row' justifyContent='space-evenly'>
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div">
                                                        ต้นทุนเครื่อง
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#9e9e9e'>
                                                    {currencyFormat.format(shopMonthlySummaryQuery.data?.totalDeviceCostAmount || 0)}
                                                </Typography>
                                            </Stack>
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div">
                                                        ค่าคอม
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#9e9e9e'>
                                                    {currencyFormat.format(shopMonthlySummaryQuery.data?.totalCommissionAmount || 0)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    : <></>
                                }

                            </CardContent>
                        </Card>
                    </Stack>

                </Box>
                <Typography color='#9e9e9e' component="div" sx={{ mt: 4, py: 2, fontSize: 20 }}>
                    สรุปรายปี
                </Typography>
                <Stack direction='row' gap={2} useFlexGap flexWrap="wrap">

                    <Box sx={{ width: { xs: '100%', lg: '49%' } }}>
                        <Stack direction='column' gap={2}>

                            <Card sx={{ width: '100%' }}>
                                {//TODO: on click go to loan
                                }
                                <CardContent sx={{ padding: 2 }}>
                                    {shopYearlySummaryQuery.isFetched
                                        ?
                                        <Stack alignContent='stretch' justifyContent='space-evenly'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body1" component="div">
                                                    ยอดสินเชื่อ
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="h2" component="div">
                                                    {currencyFormat.format(shopYearlySummaryQuery.data?.loanTotalAmount || 0)}
                                                </Typography>
                                            </Box>
                                            <Divider sx={{ my: 1 }} />
                                            <Stack direction='row' justifyContent='space-evenly'>
                                                <Stack alignContent='center' alignItems='center'>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Typography variant="body2" component="div">
                                                            ยอดเงินดาวน์
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="h5" color='#9e9e9e'>
                                                        {currencyFormat.format(shopYearlySummaryQuery.data?.loanTotalDownAmount || 0)}
                                                    </Typography>
                                                </Stack>
                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                <Stack alignContent='center' alignItems='center'>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Typography variant="body2" component="div" >
                                                            ยอดค่างวด
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="h5" color='#aed581'>
                                                        {currencyFormat.format(shopYearlySummaryQuery.data?.loanTotalRepaymentAmount || 0)}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        : <></>
                                    }
                                </CardContent>
                            </Card>
                        </Stack>
                    </Box>
                    <Box sx={{ width: { xs: '100%', lg: '49%' } }}>
                        <Stack direction='column' gap={2}>
                            <Card sx={{ width: '100%' }}>
                                {//TODO: on click go to payment list
                                }
                                <CardContent sx={{ padding: 2 }}>
                                    {shopYearlySummaryQuery.isFetched
                                        ?
                                        <Stack alignContent='stretch' justifyContent='space-evenly'>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="body1" component="div">
                                                    จำนวนสินเชื่อ
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Typography variant="h2" component="div">
                                                    {shopYearlySummaryQuery.data?.loanCount || 0}
                                                </Typography>
                                            </Box>
                                            <Divider sx={{ my: 1 }} />
                                            <Stack direction='row' justifyContent='space-evenly'>
                                                <Stack alignContent='center' alignItems='center'>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Typography variant="body2" component="div">
                                                            กำลังผ่อน
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="h5">
                                                        {shopYearlySummaryQuery.data?.loanActiveCount}
                                                    </Typography>
                                                </Stack>
                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                <Stack alignContent='center' alignItems='center'>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Typography variant="body2" component="div">
                                                            ปิดสินเชื่อ
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="h5" color='#aed581'>
                                                        {shopYearlySummaryQuery.data?.loanClosedCount}
                                                    </Typography>
                                                </Stack>
                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                <Stack alignContent='center' alignItems='center'>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Typography variant="body2" component="div">
                                                            หนี้เสีย
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="h5" color='#ef9a9a'>
                                                        {shopYearlySummaryQuery.data?.loanNonPerformingCount}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        : <></>
                                    }
                                </CardContent>
                            </Card>
                        </Stack>
                    </Box >
                    <Stack direction={{ lg: 'row' }} sx={{ width: '100%', pb: 2 }} gap={2} useFlexGap flexWrap="wrap">
                        <Card sx={{ width: { lg: '49%' } }}>
                            {//TODO: on click go to loan
                            }
                            <CardContent sx={{ padding: 2 }}>
                                {shopMonthlySummaryQuery.isFetched
                                    ?
                                    <Stack alignContent='stretch' justifyContent='space-evenly'>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Typography variant="body1" component="div">
                                                {`รายได้ (${shopYearlySummaryQuery.data?.transactionCount || 0} รายการ)`}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Typography variant="h2" component="div">
                                                {currencyFormat.format(shopYearlySummaryQuery.data?.totalPaymentAmount || 0)}
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ my: 1 }} />
                                        <Stack direction='row' justifyContent='space-evenly'>
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div">
                                                        {`เงินดาว (${shopYearlySummaryQuery.data?.downPaymentTransactionCount || 0} รายการ)`}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#9e9e9e'>
                                                    {currencyFormat.format(shopYearlySummaryQuery.data?.totalDownPaymentAmount || 0)}
                                                </Typography>
                                            </Stack>
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div">
                                                        {`ค่างวด (${shopYearlySummaryQuery.data?.repaymentTransactionCount || 0} รายการ)`}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#aed581'>
                                                    {currencyFormat.format(shopYearlySummaryQuery.data?.totalRepaymentAmount || 0)}
                                                </Typography>
                                            </Stack>
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div" >
                                                        {`ค่าปรับ (${shopYearlySummaryQuery.data?.finePaymentTransactionCount || 0} รายการ)`}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#03a9f4'>
                                                    {currencyFormat.format(shopYearlySummaryQuery.data?.totalFinePaymentAmount || 0)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    : <></>
                                }

                            </CardContent>
                        </Card>

                        <Card sx={{ width: { lg: '49%' } }}>
                            {//TODO: on click go to loan
                            }
                            <CardContent sx={{ padding: 2 }}>
                                {shopYearlySummaryQuery.isFetched
                                    ?
                                    <Stack alignContent='stretch' justifyContent='space-evenly'>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Typography variant="body1" component="div">
                                                รายจ่าย
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                            <Typography variant="h2" component="div" color='#ef9a9a'>
                                                {currencyFormat.format(shopYearlySummaryQuery.data?.totalCostAmount || 0)}
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ my: 1 }} />
                                        <Stack direction='row' justifyContent='space-evenly'>
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div">
                                                        ต้นทุนเครื่อง
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#9e9e9e'>
                                                    {currencyFormat.format(shopYearlySummaryQuery.data?.totalDeviceCostAmount || 0)}
                                                </Typography>
                                            </Stack>
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <Stack alignContent='center' alignItems='center'>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography variant="body2" component="div">
                                                        ค่าคอม
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h5" color='#9e9e9e'>
                                                    {currencyFormat.format(shopYearlySummaryQuery.data?.totalCommissionAmount || 0)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    : <></>
                                }

                            </CardContent>
                        </Card>
                    </Stack>
                </Stack>
            </Box >
        </OwnerCheck >
    )
}