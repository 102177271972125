import { InputLabel, styled } from "@mui/material";


const CustomInputLabel = styled(InputLabel)({
    position: "relative",
    transform: "none",
    marginBottom: "5px",
});

export default CustomInputLabel
