/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ShopEvent } from '../models/ShopEvent';
import type { ShopMemberInvitationRequest } from '../models/ShopMemberInvitationRequest';
import type { ShopMemberRemoveRequest } from '../models/ShopMemberRemoveRequest';
import type { ShopMembersResponse } from '../models/ShopMembersResponse';
import type { ShopMemberUpdateRequest } from '../models/ShopMemberUpdateRequest';
import type { ShopSummaryResponse } from '../models/ShopSummaryResponse';
import type { StorageEvent } from '../models/StorageEvent';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ShopEvent OK
     * @throws ApiError
     */
    public getShops(): CancelablePromise<Array<ShopEvent>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopEvent OK
     * @throws ApiError
     */
    public createShop({
        requestBody,
    }: {
        requestBody: ShopEvent,
    }): CancelablePromise<ShopEvent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopEvent OK
     * @throws ApiError
     */
    public shopMemberUpdate({
        shopId,
        requestBody,
    }: {
        shopId: string,
        requestBody: ShopMemberUpdateRequest,
    }): CancelablePromise<ShopEvent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/{shopId}/members/update',
            path: {
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopEvent OK
     * @throws ApiError
     */
    public shopMemberCreateInvitation({
        shopId,
        requestBody,
    }: {
        shopId: string,
        requestBody: ShopMemberInvitationRequest,
    }): CancelablePromise<ShopEvent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/{shopId}/members/invitation',
            path: {
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopEvent OK
     * @throws ApiError
     */
    public getShopById({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopEvent OK
     * @throws ApiError
     */
    public updateShop({
        shopId,
        requestBody,
    }: {
        shopId: string,
        requestBody: ShopEvent,
    }): CancelablePromise<ShopEvent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns StorageEvent OK
     * @throws ApiError
     */
    public getShopUploadUrl({
        shopId,
        fileName,
    }: {
        shopId: string,
        fileName: string,
    }): CancelablePromise<StorageEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/{shopId}/upload-url',
            path: {
                'shopId': shopId,
            },
            query: {
                'fileName': fileName,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopSummaryResponse OK
     * @throws ApiError
     */
    public summary({
        shopId,
        year,
    }: {
        shopId: string,
        year: number,
    }): CancelablePromise<ShopSummaryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/{shopId}/summary',
            path: {
                'shopId': shopId,
            },
            query: {
                'year': year,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopMembersResponse OK
     * @throws ApiError
     */
    public getShopMemberUsers({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopMembersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/{shopId}/members',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns StorageEvent OK
     * @throws ApiError
     */
    public getShopDownloadUrl({
        shopId,
        fileName,
    }: {
        shopId: string,
        fileName: string,
    }): CancelablePromise<StorageEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/{shopId}/download-url',
            path: {
                'shopId': shopId,
            },
            query: {
                'fileName': fileName,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopEvent OK
     * @throws ApiError
     */
    public shopMemberRemove({
        shopId,
        requestBody,
    }: {
        shopId: string,
        requestBody: ShopMemberRemoveRequest,
    }): CancelablePromise<ShopEvent> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/shops/{shopId}/members/remove',
            path: {
                'shopId': shopId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
