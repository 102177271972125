import { Avatar, Box, Button, Card, CardContent, Divider, FormControl, IconButton, List, MenuItem, Select, Stack, styled, TextField, Typography } from "@mui/material"
import CustomInputLabel from "../component/CustomInputLabel"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useShopQuery, useShopUpdateMutation } from "../client/query-client"
import { useEffect, useState } from "react"
import { PaymentChannel, ShopEvent } from "../client/motal-api/generated"
import { Delete } from "@mui/icons-material"
import toast from 'react-hot-toast';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RenderPaymentChannel from "../component/RenderPaymentChannel"
import ShopIcon from '@mui/icons-material/Shop';
import { useMotalClient } from "../client/motal-api"
import { useQuery } from "@tanstack/react-query"
import { generateRandomString } from "../util/random-string"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// import UploadZone from "../component/UploadZone"
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
export const ShopDetailScreen = () => {
    const client = useMotalClient();
    const { shopId } = useParams()
    const shopQuery = useShopQuery(shopId || '')
    const shopUpdateMutation = useShopUpdateMutation(shopId || '')
    // const [file, setFile] = useState<File | null>(null)
    const { control, handleSubmit, reset, setValue, formState: { errors, isDirty } } = useForm<ShopEvent>({
        defaultValues: {
            address: '',
            name: '',
            phoneNumber: '',
            facebook: '',
            lineId: ''
        },
        mode: 'all'
    });
    const profileUrlQuery = useQuery({
        queryKey: ['shop-profile', { shopId }],
        queryFn: async () => {
            const response = await client.shop.getShopDownloadUrl({
                fileName: shopQuery.data?.shopPrimaryImageFile || '', shopId: shopId!
            });
            return response.url;
        },
        enabled: !!shopQuery.data?.shopPrimaryImageFile
    }
    )
    const [tempProfileImage, setTempProfileImage] = useState<File | null>(null)

    const uploadFile = async ({ file, fileName }: { file: File, fileName: string }) => {
        try {
            const response = await client.shop.getShopUploadUrl({ fileName: fileName, shopId: shopId! });
            const { url } = response;

            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url!, true);
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.onload = () => {
                if (xhr.status === 200) {
                    console.log('upload success')
                } else {
                    console.error('Upload failed', xhr.responseText);
                }
            };

            xhr.onerror = () => {
                console.error('Upload error', xhr.responseText);
            };

            xhr.send(file);
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    const paymentChannelsFieldArray = useFieldArray({
        control,
        name: 'paymentChannels'
    });

    useEffect(() => {
        if (!shopQuery.isLoading && shopQuery.data) {
            reset({ ...shopQuery.data, paymentChannels: shopQuery.data.paymentChannels?.filter(pm => pm.status === PaymentChannel.status.ACTIVE) });
        }
    }, [shopQuery.isLoading, shopQuery.data, reset]);

    const onSubmit = () => {
        handleSubmit((data) => {
            if (tempProfileImage) {
                uploadFile({ file: tempProfileImage, fileName: `profile_${shopId}` });
            }
            shopUpdateMutation.mutate({ body: data, id: shopId! }, {
                onSuccess: () => {
                    toast.success('บันทึกข้อมูลสำเร็จ')
                }
            })
        })()
    }

    // const [channels, setChannels] = useState<any>([]);
    const [newChannel, setNewChannel] = useState<PaymentChannel>({ type: PaymentChannel.type.BANK_ACCOUNT, accountId: '', status: PaymentChannel.status.ACTIVE, accountName: '' });

    const handleAddChannel = () => {
        if (newChannel.type && (newChannel.accountId || newChannel.qrCodeImageFile)) {
            paymentChannelsFieldArray.append(newChannel);
            // setChannels([...channels, newChannel]);
            // setFile(null);
            setNewChannel({ type: PaymentChannel.type.BANK_ACCOUNT, accountId: '', bankCode: undefined, status: PaymentChannel.status.ACTIVE, accountName: '' });
        }
    };

    // const handleRemoveChannel = (index: any) => {
    //     const newChannels = [...channels];
    //     newChannels.splice(index, 1);
    //     setChannels(newChannels);
    // };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setNewChannel({ ...newChannel, [name]: value });
    };
    const handleBangCodeChange = (e: any) => {
        setNewChannel({ ...newChannel, bankCode: e.target.value });
    }
    return (
        <>
            <Box sx={{ p: 2, mb: 16 }}>
                <Stack direction='row' alignItems='center'>
                    <Typography variant="h5" p={2}>ข้อมูลร้านค้า</Typography>
                </Stack>
                <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" >
                    <Card sx={{ my: 1, width: { xs: '100%', lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack gap={2}>
                                <Typography variant="h6">ข้อมูลหลัก</Typography>
                                <CustomInputLabel>รูปโปรไฟล์</CustomInputLabel>
                                <Stack direction={'column'} gap={2} justifyContent='center' alignItems='flex-start'>
                                    <Avatar src={
                                        tempProfileImage ? URL.createObjectURL(tempProfileImage) :
                                            profileUrlQuery.data
                                    } sx={{ width: '150px', height: '150px' }} variant="circular">
                                        <ShopIcon sx={{ fontSize: '80px' }} />
                                    </Avatar>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        color='primary'
                                        sx={{ width: 150 }}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        อัพโหลด
                                        <VisuallyHiddenInput type="file" onChange={(event) => {
                                            const file = event.target.files?.[0]; // Add null check
                                            if (file) {
                                                setTempProfileImage(file);
                                                setValue('shopPrimaryImageFile', `profile_${shopId}`, { shouldDirty: true });
                                            }
                                        }} />
                                    </Button>
                                </Stack>
                                <Stack gap={2}>
                                    <FormControl fullWidth error={!!errors.name}>
                                        <CustomInputLabel>ชื่อร้านค้า</CustomInputLabel>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={{ required: "ข้อมูลไม่ครบ" }}
                                            render={({ field }) => <TextField  {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.name && <Typography color="error">{errors.name.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth error={!!errors.address}>
                                        <CustomInputLabel>ที่อยู่ *</CustomInputLabel>
                                        <Controller
                                            name="address"
                                            control={control}
                                            rules={{ required: "ข้อมูลไม่ครบ" }}
                                            render={({ field }) => <TextField  {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.address && <Typography color="error">{errors.address.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth error={!!errors.phoneNumber}>
                                        <CustomInputLabel>หมายเลขโทรศัพท์ *</CustomInputLabel>
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            rules={{ required: "ข้อมูลไม่ครบ" }}
                                            render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.phoneNumber && <Typography color="error">{errors.phoneNumber.message}</Typography>}
                                    </FormControl>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth error={!!errors.facebook}>
                                            <CustomInputLabel>Facebook ร้าน *</CustomInputLabel>
                                            <Controller
                                                name="facebook"
                                                control={control}
                                                rules={{ required: "ข้อมูลไม่ครบ", pattern: { value: /^(ftp|http|https):\/\/[^ "]+$/, message: 'กรุณากรอก URL ของ Facebook ให้ถูกต้อง' } }}
                                                render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                            />
                                            {errors.facebook && <Typography color="error">{errors.facebook.message}</Typography>}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>LINE ID ร้าน</CustomInputLabel>
                                            <Controller
                                                name="lineId"
                                                control={control}
                                                render={({ field }) => <TextField  {...field} size='small' variant="outlined" />}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack>
                                        <CustomInputLabel>ลิ้งสำหรับลูกค้า</CustomInputLabel>
                                        <Stack direction='row' gap={1} alignItems='flex-end' justifyContent='flex-start'>
                                            <Typography sx={{ lineHeight: '32px' }}>{shopId ? `https://installment.motalvip.com?shopId=${shopId}` : '-'}</Typography>
                                            <IconButton size="small" onClick={() => {
                                                navigator.clipboard.writeText(`https://installment.motalvip.com?shopId=${shopId}`)
                                                    .then(() => {
                                                        toast.success('คัดลอกสำเร็จ');
                                                    })
                                            }}>
                                                <ContentCopyIcon fontSize='inherit' />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ my: 1, width: { xs: '100%', lg: '49%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack gap={2}>
                                <Typography variant="h6">
                                    ช่องทางชำระเงิน
                                </Typography>


                                <List>
                                    {paymentChannelsFieldArray.fields.map((channel, index) => (
                                        <Box m={1}>
                                            <RenderPaymentChannel paymentChannel={channel} onDelete={() => paymentChannelsFieldArray.remove(index)} />
                                        </Box>
                                    ))}
                                </List>
                                <Divider />
                                <Stack gap={2}>
                                    <Stack gap={2} direction={{ lg: 'row' }}>
                                        <FormControl fullWidth>
                                            <CustomInputLabel id="type-label">ประเภท</CustomInputLabel>
                                            <Select
                                                labelId="type-label"
                                                id="type"
                                                name="type"
                                                value={newChannel.type}
                                                onChange={handleInputChange}
                                                // label="Type"
                                                size='small' variant="outlined"
                                            >
                                                <MenuItem value={PaymentChannel.type.BANK_ACCOUNT}>บัญชีธนาคาร</MenuItem>
                                                <MenuItem value={PaymentChannel.type.PROMPT_PAY}>พร้อมเพย์</MenuItem>
                                                <MenuItem value={PaymentChannel.type.PROMPT_PAY_QR}>พร้อมเพย์ (QR)</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth></FormControl>
                                    </Stack>
                                    <Stack direction={{ lg: 'row' }} gap={2}>

                                        <FormControl fullWidth sx={{ display: newChannel.type === PaymentChannel.type.BANK_ACCOUNT ? '' : 'none' }}>
                                            <CustomInputLabel id="type-label">ธนาคาร</CustomInputLabel>
                                            <Select
                                                labelId="bank-code-label"
                                                id="bangCode"
                                                name="bangCode"
                                                value={newChannel.bankCode || ''}
                                                onChange={handleBangCodeChange}
                                                size='small'
                                                variant="outlined"
                                                placeholder="เลือกธนาคาร"
                                            >
                                                <MenuItem value={PaymentChannel.bankCode.BBL}>ธนาคารกรุงเทพ (BBL)</MenuItem>
                                                <MenuItem value={PaymentChannel.bankCode.BAY}>ธนาคารกรุงศรีอยุธยา (BAY)</MenuItem>
                                                <MenuItem value={PaymentChannel.bankCode.KBANK}>ธนาคารกสิกร (KBANK)</MenuItem>
                                                <MenuItem value={PaymentChannel.bankCode.SCB}>ธนาคารไทยพาณิชย์ (SCB)</MenuItem>
                                                <MenuItem value={PaymentChannel.bankCode.KTB}>ธนาคารกรุงไทย (KTB)</MenuItem>
                                                <MenuItem value={PaymentChannel.bankCode.TTB}>ธนาคารทีเอ็มบีธนชาต (TTB)</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {
                                            newChannel.type === PaymentChannel.type.PROMPT_PAY_QR ?
                                                newChannel.qrCodeImageFile ?
                                                    <Stack>
                                                        <CustomInputLabel id="type-label">รูป QR Code</CustomInputLabel>
                                                        <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                                                            <Typography>{newChannel.qrCodeImageFile}</Typography>
                                                            <IconButton onClick={() => {
                                                                // setFile(null); 
                                                                setNewChannel(prev => ({ ...prev, qrCodeImageFile: undefined }))
                                                            }}><Delete /></IconButton>

                                                        </Stack>
                                                    </Stack>
                                                    :
                                                    <FormControl fullWidth>
                                                        <CustomInputLabel id="type-label">รูป QR Code</CustomInputLabel>
                                                        <Button
                                                            component="label"
                                                            role={undefined}
                                                            variant="outlined"
                                                            tabIndex={-1}
                                                            color='primary'
                                                            startIcon={<CloudUploadIcon />}
                                                        >
                                                            อัพโหลดรูป QR Code
                                                            <VisuallyHiddenInput type="file" onChange={async (event) => {
                                                                const file = event.target.files?.[0]; // Add null check
                                                                console.log('file-name', file?.name)
                                                                const fileName = `qr_${generateRandomString(32)}_${file?.name}`

                                                                if (file) {
                                                                    setNewChannel(prev => ({ ...prev, qrCodeImageFile: fileName }));

                                                                    await uploadFile({ file, fileName: fileName });
                                                                }

                                                            }} />
                                                        </Button>
                                                    </FormControl>
                                                :
                                                <FormControl fullWidth>
                                                    <CustomInputLabel id="type-label">{newChannel.type === PaymentChannel.type.BANK_ACCOUNT ? 'เลขที่บัญชี' : 'บัญชีพร้อมเพย์'}</CustomInputLabel>
                                                    <TextField

                                                        fullWidth
                                                        id="accountId"
                                                        name="accountId"
                                                        // label="Account ID"
                                                        value={newChannel.accountId}
                                                        onChange={handleInputChange}
                                                        size='small' variant="outlined"
                                                    />
                                                </FormControl>
                                        }


                                    </Stack>
                                    <FormControl fullWidth>
                                        <CustomInputLabel id="type-label">{'ชื่อบัญชี'}</CustomInputLabel>
                                        <TextField
                                            fullWidth
                                            id="accountName"
                                            name="accountName"
                                            // label="Account ID"
                                            value={newChannel.accountName}
                                            onChange={(e) => {
                                                setNewChannel((prev) => ({ ...prev, accountName: e.target.value }))
                                            }}
                                            size='small' variant="outlined"
                                        />
                                    </FormControl>
                                </Stack>
                                <Box mt={2} sx={{ width: '100%' }} >
                                    <Button variant="contained" color="primary" onClick={handleAddChannel}>
                                        เพิ่มช่องทางชำระเงิน
                                    </Button>
                                </Box>

                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Box >
            <Box>


            </Box>
            <Box sx={{ visibility: (isDirty || tempProfileImage) ? 'visible' : 'hidden', p: 2, bgcolor: 'white', width: { xs: '100%', sm: 'calc(100% - 240px)' }, position: 'fixed', bottom: 0, zIndex: 99 }} >
                <Stack direction='row' gap={1} justifyContent='center'>
                    <Button variant='outlined' size='large'
                        onClick={() => {
                            setTempProfileImage(null)
                            reset({ ...shopQuery.data, paymentChannels: shopQuery.data?.paymentChannels?.filter(pm => pm.status === PaymentChannel.status.ACTIVE) })
                        }}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>ยกเลิก</Button>
                    <Button variant='contained' size='large'
                        onClick={onSubmit}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>บันทึก</Button>
                </Stack>
            </Box>

        </>
    )
}