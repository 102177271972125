import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useMotalClient } from "../motal-api";
import { CalculateRepaymentScheduleRequest, LoanEvent, PaymentEvent, ShopEvent } from "../motal-api/generated";
import { useDialog } from "../../context/DialogContext";

//query key enum
export enum QueryKey {
    SHOPS = "SHOPS",
    SHOP = "SHOP",
    LOAN = "LOAN",
    LOANS = "LOANS",
    PAYMENT = "PAYMENT",
    USER = "USER"
}

export const useShopsQuery = () => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.SHOPS], queryFn: () => motalClient.shop.getShops() })
}
export const useShopsQuerySuspense = () => {
    const motalClient = useMotalClient()
    return useSuspenseQuery({ queryKey: [QueryKey.SHOPS], queryFn: () => motalClient.shop.getShops() })
}
export const useShopQuery = (shopId: string) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.SHOP, shopId], queryFn: () => motalClient.shop.getShopById({ shopId: shopId }) })
}
export const useCreateShopMutation = () => {
    const motalClient = useMotalClient()
    return useMutation({ mutationKey: [QueryKey.SHOPS], mutationFn: (req: ShopEvent) => motalClient.shop.createShop({ requestBody: req }) })
}

export const useLoanQuery = (loanId?: string) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.LOAN, loanId], queryFn: () => motalClient.loan.getLoan({ loanId: loanId! }), enabled: !!loanId })
}

export const useLoansQuery = (req: {
    shopId: string;
    search?: string;
    scheduledDaysFrom?: number;
    scheduledDaysTo?: number;
    transactionDateFrom?: string;
    transactionDateTo?: string;
    page?: number;
    limit?: number;
    status?: LoanEvent.status[],
    sortBy?: string;
    sort?: string;
}) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.LOANS, req.shopId, req.search, req.page, req.scheduledDaysFrom, req.scheduledDaysTo, req.status, req.sortBy, req.sort, req.transactionDateFrom, req.transactionDateTo], queryFn: () => motalClient.loan.searchLoans(req) })
}
export const useUpdateLoanMutation = () => {
    const dialog = useDialog();
    const motalClient = useMotalClient()
    return useMutation({
        mutationKey: [QueryKey.LOANS],
        mutationFn: (req: { loan: LoanEvent, loanId: string }) => motalClient.loan.updateLoan({ loanId: req.loanId, requestBody: req.loan }),
        onError: (error) => {
            dialog.showDialog({
                title: 'เกิดความผิดพลาด',
                description: '',
                onConfirm: () => { }

            })
        }
    })

}

export const useBlacklistSearchQuery = (req: {
    shopId?: string;
    search?: string;
    scheduledDaysFrom?: number;
    scheduledDaysTo?: number;
    page?: number;
    limit?: number;
}, enabled: boolean = true) => {
    const motalClient = useMotalClient()
    return useQuery({
        queryKey: [QueryKey.LOANS, 'blacklist', req.page, req.limit, req.search, req.scheduledDaysFrom, req.scheduledDaysTo],
        queryFn: () => motalClient.loan.searchBlacklistLoans(req),
        enabled: enabled
    })
}

export const useCreateLoanMutation = () => {
    const dialog = useDialog();
    const motalClient = useMotalClient()
    return useMutation({
        mutationKey: [QueryKey.LOANS, 'create'],
        mutationFn: (req: LoanEvent) => motalClient.loan.createLoan({ requestBody: req }),
        onError: (error) => {
            dialog.showDialog({
                title: 'Error',
                description: error.message || 'An error occurred',
                onConfirm: () => { }

            })
        }
    })
}

export const useCalculatePaymentScheduleQuery = (req: CalculateRepaymentScheduleRequest, enabled: boolean = true) => {
    const motalClient = useMotalClient()
    return useQuery({
        queryKey: [QueryKey.PAYMENT, req.downPaymentAmount, JSON.stringify(req)],
        queryFn: () => motalClient.payment.previewRepayment({ requestBody: req }),
        enabled: enabled && req.downPaymentAmount !== 0 &&
            !!req.repaymentTermAmount &&
            !!req.repaymentTermCount &&
            !!req.repaymentTermFrequency &&
            req.repaymentTermFrequencyType !== undefined &&
            req.firstRepaymentDate !== undefined &&
            req.downPaymentDate !== undefined
    })
}

export const useUserProfileMutation = () => {
    const motalClient = useMotalClient()
    return useMutation({ mutationKey: [QueryKey.USER], mutationFn: () => motalClient.user.getProfile() })
}
export const useUserProfileQuery = () => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.USER], queryFn: () => motalClient.user.getProfile(), staleTime: 1000 * 60 * 60 * 24 })
}
export const usePaymentsQuery = (loanId: string, shopId: string, limit: number, enabled: boolean = true) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.PAYMENT, loanId], queryFn: () => motalClient.payment.searchPayment({ loanId, shopId, limit }), enabled: !!loanId && !!shopId && enabled })
}
export const usePaymentQuery = (paymentId: string, enabled: boolean = true

) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.PAYMENT, paymentId], queryFn: () => motalClient.payment.getPaymentById({ paymentId }), enabled: !!paymentId && enabled })
}

export const usePaymentUpdateMutation = () => {
    const dialog = useDialog();
    const motalClient = useMotalClient()
    return useMutation({
        mutationKey: [QueryKey.PAYMENT],
        mutationFn: (req: { payment: PaymentEvent, id: string }) => motalClient.payment.updatePayment({ requestBody: req.payment, paymentId: req.id }),
        onError: (error) => {
            dialog.showDialog({
                title: 'Error',
                description: error.message || 'An error occurred',
                onConfirm: () => { }
            })
        }
    })
}

export const usePaymentCreateMutation = () => {
    const dialog = useDialog();
    const motalClient = useMotalClient()
    return useMutation({
        mutationKey: [QueryKey.PAYMENT],
        mutationFn: (req: { payment: PaymentEvent }) => motalClient.payment.createPayment({ requestBody: req.payment }),
        onError: (error) => {
            dialog.showDialog({
                title: 'Error',
                description: error.message || 'An error occurred',
                onConfirm: () => { }
            })
        }
    })
}

export const useSummaryQuery = (shopId: string, year: number) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.SHOP, shopId, year], queryFn: () => motalClient.shop.summary({ shopId, year }) })
}

export const usePaymentQueryByShopId = (shopId: string, page: number = 1) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.PAYMENT, shopId, page], queryFn: () => motalClient.payment.searchPayment({ shopId, page },) })
}

// Shop
export const useShopUpdateMutation = (shopId: string) => {
    const dialog = useDialog();
    const motalClient = useMotalClient()
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [QueryKey.SHOP, shopId],
        mutationFn: (req: { body: ShopEvent, id: string }) => motalClient.shop.updateShop({ shopId: req.id, requestBody: req.body }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKey.SHOP, shopId] })
        },
        onError: (error) => {
            dialog.showDialog({
                title: 'Error',
                description: error.message || 'An error occurred',
                onConfirm: () => { }
            })
        }
    })
}

export const useShopMemberQuery = (shopId: string) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.USER, shopId], queryFn: () => motalClient.shop.getShopMemberUsers({ shopId }), enabled: !!shopId })
}

export const useShopMonthlySummaryQuery = (shopId: string, year: number, month: number) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.SHOP, shopId, year, month], queryFn: () => motalClient.shopDashboard.getMonthlySummary({ requestBody: { shopId, month, year } }) })
}

export const useShopYearlySummaryQuery = (shopId: string, year: number) => {
    const motalClient = useMotalClient()
    return useQuery({ queryKey: [QueryKey.SHOP, shopId, year], queryFn: () => motalClient.shopDashboard.getYearlySummary({ requestBody: { shopId, year } }) })
}
// export const useDownloadExcelQuery = (req: {
//     shopId: string;
//     search?: string;
//     scheduledDaysFrom?: number;
//     scheduledDaysTo?: number;
//     transactionDateFrom?: string;
//     transactionDateTo?: string;
//     status?: LoanEvent.status[],
//     sortBy?: string;
//     sort?: string;
// }) => {
//     const motalClient = useMotalClient()
//     return useQuery({
//         queryKey: ['excel', QueryKey.LOANS, req.shopId, req.search, req.scheduledDaysFrom, req.scheduledDaysTo, req.status, req.sortBy, req.sort, req.transactionDateFrom, req.transactionDateTo], queryFn: () => motalClient.loan.downloadLoans({
//             shopId: req.shopId,
//             search: req.search,
//             scheduledDaysFrom: req.scheduledDaysFrom,
//             scheduledDaysTo: req.scheduledDaysTo,
//             transactionDateFrom: req.transactionDateFrom,
//             transactionDateTo: req.transactionDateTo,
//             status: req.status,
//             sortBy: req.sortBy,
//             sort: req.sort
//         })
//     })
// }