import { useEffect, useState } from "react"
import { PaymentEvent } from "../client/motal-api/generated"
import DropdownChip from "./DropDownChip"

type PaymentStatusChipProps = {
    status: PaymentEvent.status
    selectable?: boolean
    onSelect?: (option: PaymentEvent.status) => void;
    disabled?: boolean;
}

export const PaymentStatusChip: React.FC<PaymentStatusChipProps> = ({ disabled, status, selectable = false, onSelect = () => { } }) => {

    const [selectedOption, setSelectedOption] = useState<PaymentEvent.status>(status);

    useEffect(() => {
        setSelectedOption(status)
    }, [status])

    const handleSelect = (option: PaymentEvent.status) => {
        setSelectedOption(option);
        onSelect(option);
    }

    const options = [
        { value: PaymentEvent.status.PENDING, display: 'รอรับชำระ' },
        { value: PaymentEvent.status.COMPLETED, display: 'ชำระเงินแล้ว' },
        { value: PaymentEvent.status.CANCEL, display: 'ยกเลิก' },
    ];

    const colorMapping = {
        'ยังไม่ถึงกำหนด': '#e0e0e0',
        'รอรับชำระ': '#bbdefb',
        'ชำระเงินแล้ว': '#aed581',
        'เกินกำหนด': '#ef9a9a',
        'ยกเลิก': '#e0e0e0',
    };

    return (
        <DropdownChip
            disabled={disabled}
            label={options.find(o => o.value === selectedOption)!.display}
            options={options}
            onSelect={handleSelect}
            colorMapping={colorMapping}
            selectable={selectable}
        />
    )

}