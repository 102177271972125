
import { Box, Divider, List, ListItem, Toolbar, Typography } from '@mui/material';
import { useShopContext } from '../../context/ShopContextProvider';
import { ShopMenuItem } from './ShopMenuItem';

type ShopDrawerProps = {
    shopId: string,
}

export const ShopDrawer = (props: ShopDrawerProps) => {
    const shopCtx = useShopContext()

    return (
        <div>
            <Toolbar />
            <Divider />
            <List sx={{ p: 0 }}>
                <Box
                    sx={{
                        pt: 2,
                        pb: 1,
                        px: 3
                    }}
                >
                    <Typography variant='body2' color='#757575'>ดำเนินงาน</Typography>
                </Box>
                {
                    shopCtx.menuData?.filter(e => e.display).map((menu) => {
                        return (
                            <ListItem key={menu.label} disablePadding>
                                <ShopMenuItem
                                    menuId={menu.key}
                                    link={menu.key.replace(':shopId', props.shopId)}
                                    icon={menu.icon}
                                    label={menu.label}
                                    disabled={menu.key === '/:shopId/coming-soon'}
                                />
                            </ListItem>
                        )
                    })
                }
                <Box sx={{ p: 2 }} />
                <Divider />
                <Box
                    sx={{
                        pt: 2,
                        pb: 1,
                        px: 3
                    }}
                >
                    <Typography variant='body2' color='#757575'>ตั้งค่า</Typography>
                </Box>
                {
                    shopCtx.shopMenuData?.filter(e => e.display).map((menu) => {
                        return (
                            <ListItem key={menu.label} disablePadding>
                                <ShopMenuItem
                                    menuId={menu.key}
                                    link={menu.key.replace(':shopId', props.shopId)}
                                    icon={menu.icon}
                                    label={menu.label}
                                    disabled={menu.key === '/:shopId/coming-soon'}
                                />
                            </ListItem>
                        )
                    })
                }
                {/* <Box sx={{
                    m: 2,
                    py: '4px',
                    px: 2,
                    borderRadius: 10,
                    backgroundColor: shopCtx.remainingDays > 30 ? '#aed581' : '#fff59d'
                }}>
                    <Typography
                        textAlign='center'
                        variant='body2'
                        color='black'>{`ใช้งานได้อีก ${shopCtx.remainingDays} วัน`}
                    </Typography>
                </Box> */}

            </List>
        </div >
    )
}