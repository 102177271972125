/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopMember = {
    userId?: string;
    role?: ShopMember.role;
};
export namespace ShopMember {
    export enum role {
        OWNER = 'OWNER',
        STAFF = 'STAFF',
    }
}

