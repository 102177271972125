import { UserButton } from "@clerk/clerk-react";
import { AppBar, Box, Breadcrumbs, CssBaseline, Link, Toolbar, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

export const BasicLayout = () => {

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundImage: 'linear-gradient(to right, #263238, #37474f)'
                }}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <Typography variant='h6' fontWeight='bold' color='white' sx={{ textShadow: '2px 2px 2px #263238' }} >MotalVip</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Box>
                    <UserButton />
                </Toolbar>
            </AppBar>
            <Box
                component="main"
                sx={{ flexGrow: 1, backgroundColor: '#fafafa', minHeight: '100vh' }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box >
    )
}