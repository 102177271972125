import { Box, Button, Container, FormControl, FormLabel, Paper, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateShopMutation } from "../client/query-client";
import { ShopEvent } from "../client/motal-api/generated";
import toast from 'react-hot-toast';
import { useDialog } from "../context/DialogContext";
export const ShopRegisterScreen = () => {
    const createShopMutation = useCreateShopMutation();
    const nav = useNavigate();
    const { control, handleSubmit, formState: { errors } } = useForm<ShopEvent>({
        defaultValues: { address: '', name: '', phoneNumber: '', facebook: '', lineId: '' },
        mode: 'all'
    });
    const dialog = useDialog();

    const onSubmit = (data: ShopEvent) => {
        createShopMutation.mutate(data, {
            onSuccess: (res) => {
                toast.success('ส่งคำขอสร้างร้านค้าสำเร็จ')
                nav(`/${res.id}`);
            },
            onError: (error) => {
                dialog.showDialog({
                    title: 'เกิดความผิดพลาด',
                    description: '',
                    onConfirm: () => { }

                })
            }
        });
    };

    return (
        <Container>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Paper sx={{ p: 4, mt: { xs: 2, sm: 2, md: 4 } }}>
                    <Stack justifyContent='center' alignItems='center' gap={2}>
                        <Typography variant="h5">ข้อมูลร้านค้า *</Typography>
                        <Typography variant="body1">กรอกรายละเอียดร้านค้าเพื่อส่งคำขอสร้าง</Typography>
                        <Box sx={{ p: 2, width: { xs: '100%', md: '50%' } }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Stack gap={2}>
                                    <FormControl fullWidth error={!!errors.name}>
                                        <FormLabel>ชื่อร้านค้า</FormLabel>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={{ required: "require" }}
                                            render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.name && <Typography color="error">{errors.name.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth error={!!errors.address}>
                                        <FormLabel>ที่อยู่ *</FormLabel>
                                        <Controller
                                            name="address"
                                            control={control}
                                            rules={{ required: "require" }}
                                            render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.address && <Typography color="error">{errors.address.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth error={!!errors.phoneNumber}>
                                        <FormLabel>หมายเลขโทรศัพท์ *</FormLabel>
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            rules={{ required: "require" }}
                                            render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.phoneNumber && <Typography color="error">{errors.phoneNumber.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth error={!!errors.facebook}>
                                        <FormLabel>Facebook ร้าน *</FormLabel>
                                        <Controller
                                            name="facebook"
                                            control={control}
                                            rules={{ required: "require", pattern: { value: /^(ftp|http|https):\/\/[^ "]+$/, message: 'กรุณากรอก URL ของ Facebook ให้ถูกต้อง' } }}
                                            render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.facebook && <Typography color="error">{errors.facebook.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <FormLabel>LINE ID ร้าน</FormLabel>
                                        <Controller
                                            name="lineId"
                                            control={control}
                                            render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                        />
                                    </FormControl>
                                    <Button sx={{ my: 4 }} size='large' variant='contained' type="submit">ส่งคำขอ</Button>
                                </Stack>
                            </form>
                        </Box>
                    </Stack>
                </Paper>
            </LocalizationProvider>
        </Container>
    );
}
