import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useShopContext } from "../../context/ShopContextProvider"

export interface ShopMenuItemProps {
    menuId: string
    link: string
    icon: React.ReactNode
    label: string
    disabled?: boolean
}

export const ShopMenuItem = (props: ShopMenuItemProps) => {
    const shopCtx = useShopContext()
    const nav = useNavigate();

    return (
        <ListItemButton
            sx={{
                mx: '8px',
                my: '2px',
                borderRadius: 2,
                py: '4px',
                '&.Mui-selected': {
                    backgroundColor: '#eceff1',
                    color: 'black',
                    '& .MuiListItemIcon-root': {
                        color: 'black',
                    },
                },
            }}
            disabled={props.disabled}
            selected={props.menuId === shopCtx.selectedMenu}
            onClick={() => {
                shopCtx.setSelectedMenu(props.menuId)
                nav(props.link)
            }}
        >
            <ListItemIcon
                sx={{
                    px: 0,
                    minWidth: 36
                }}
            >
                {props.icon}
            </ListItemIcon>
            <ListItemText
                sx={{ p: 0 }}
                primary={props.label}
            />
        </ListItemButton >
    )
}