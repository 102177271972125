import { useParams } from "react-router-dom"
import { ShopMemberUser } from "../client/motal-api/generated"
import { useShopMemberQuery, useUserProfileQuery } from "../client/query-client"
type Props = {
    children?: React.ReactNode | React.ReactNode[]
}
export const OwnerCheck = (props: Props) => {
    const { shopId } = useParams()
    const shopMember = useShopMemberQuery(shopId || '')
    const userProfile = useUserProfileQuery()
    const isOwner = shopMember.data?.shopMemberUsers?.find(i => i.primaryEmail === userProfile.data?.primaryEmail)?.shopRole === ShopMemberUser.shopRole.OWNER
    return (shopMember.isLoading || userProfile.isLoading) ? null : isOwner ? <>{props.children}</> : null
}