
import { Box, Typography } from "@mui/material";


export const ComingSoonFeatureScreen = () => {

    return (
        <Box sx={{ m: 8 }}>
            <Typography variant="h3" color='grey' textAlign='center'>กำลังพัฒนา</Typography>
        </Box>
    )
}