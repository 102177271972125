/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoanEvent } from '../models/LoanEvent';
import type { PaginatedResponsePaymentEvent } from '../models/PaginatedResponsePaymentEvent';
import type { ShopEvent } from '../models/ShopEvent';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LoanPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ShopEvent OK
     * @throws ApiError
     */
    public getPublicShop({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v1/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanEvent OK
     * @throws ApiError
     */
    public getLoanById({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<LoanEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v1/loans/{loanId}',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedResponsePaymentEvent OK
     * @throws ApiError
     */
    public getPaymentsByLoanId({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<PaginatedResponsePaymentEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v1/loans/{loanId}/payments',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
