import { Delete } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Box, Chip, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
// import { useParams } from "react-router-dom";
// import { useMotalClient } from "../../client/motal-api";
import { PaymentChannel } from "../../client/motal-api/generated";
type Props = {
    paymentChannel: PaymentChannel
    onDelete?: () => void
    hideIcon?: boolean
}
const QrPaymentMethod = (props: Props) => {
    // const client = useMotalClient();
    // const { shopId } = useParams()

    const [open, setOpen] = useState<boolean>(false);
    // const qrImageQuery = useQuery({
    //     queryKey: ['qr', props.paymentChannel.accountId],
    //     queryFn: async () => {
    //         const response = await client.shop.getShopDownloadUrl({
    //             fileName: props.paymentChannel.qrCodeImageUrl || '', shopId: shopId!
    //         });
    //         return response.url;
    //     },
    //     enabled: !!props.paymentChannel.accountId
    // }
    // )
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box sx={{ border: '2px solid #eeeeee', borderRadius: 2, p: 1, backgroundColor: '#0A2A4D' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' gap={1} alignItems='center'>
                    <Box p={1}>
                        {/* <img height={28} src={'/bank-logo/prompt-pay-logo.svg'} alt='' /> */}
                        <img style={{ color: 'white' }} height={28} src={'/bank-logo/pp.svg'} alt='' />

                    </Box>
                    <Stack justifyContent='flex-start' alignItems='flex-start'>
                        <Typography color='white' sx={{ textShadow: '1px 1px 1px #263238' }}>QR Code</Typography>
                        <Typography variant='body2' color='white' sx={{ textShadow: '1px 1px 1px #263238' }}>{props.paymentChannel.accountName || '-'}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row'>
                    {!props.hideIcon &&
                        <Stack direction='row' spacing={1}>
                            <IconButton onClick={handleClickOpen}>
                                <QrCodeIcon sx={{ color: 'white' }} />
                            </IconButton>
                            {props.onDelete && <IconButton
                                sx={{ color: 'white' }}
                                onClick={() => {
                                    if (props.onDelete)
                                        props.onDelete()
                                }}>
                                {/* <ContentCopyIcon sx={{ color: 'white' }} /> */}
                                <Delete />
                            </IconButton>}
                            {/* <IconButton onClick={() => { }}>
                        <ArrowForwardIosIcon sx={{ color: 'white' }} />
                    </IconButton> */}
                        </Stack>}
                    {props.paymentChannel.status === PaymentChannel.status.INACTIVE && <Chip label="เลิกใช้งาน" variant="outlined" sx={{ borderRadius: '3px', marginRight: '3px', color: 'white', backgroundColor: 'gray' }} />}
                </Stack>
            </Stack>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    QR Code
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers >
                    <img src={props.paymentChannel.qrCodeImageUrl} alt='' style={{ objectFit: 'contain', maxWidth: '80vh' , width:'100%'}} />
                </DialogContent>

            </Dialog>
        </Box>
    );
}
export default QrPaymentMethod