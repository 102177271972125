/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ShopDashboardMonthlySummaryRequest } from '../models/ShopDashboardMonthlySummaryRequest';
import type { ShopDashboardYearlySummaryRequest } from '../models/ShopDashboardYearlySummaryRequest';
import type { ShopMonthlySummaryResponse } from '../models/ShopMonthlySummaryResponse';
import type { ShopYearlySummaryResponse } from '../models/ShopYearlySummaryResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ShopDashboardService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ShopYearlySummaryResponse OK
     * @throws ApiError
     */
    public getYearlySummary({
        requestBody,
    }: {
        requestBody: ShopDashboardYearlySummaryRequest,
    }): CancelablePromise<ShopYearlySummaryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shop-dashboard/yearly-summary',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopMonthlySummaryResponse OK
     * @throws ApiError
     */
    public getMonthlySummary({
        requestBody,
    }: {
        requestBody: ShopDashboardMonthlySummaryRequest,
    }): CancelablePromise<ShopMonthlySummaryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shop-dashboard/monthly-summary',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
