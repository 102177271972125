/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CalculateRepaymentScheduleRequest = {
    downPaymentAmount?: number;
    repaymentTermAmount?: number;
    repaymentTermCount?: number;
    downPaymentDate?: string;
    firstRepaymentDate?: string;
    repaymentTermFrequency?: number;
    repaymentTermFrequencyType?: CalculateRepaymentScheduleRequest.repaymentTermFrequencyType;
};
export namespace CalculateRepaymentScheduleRequest {
    export enum repaymentTermFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

