import { Avatar, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ShopMember, ShopMemberInvitationRequest, ShopMemberUser } from "../client/motal-api/generated";

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useMotalClient } from "../client/motal-api";
import { QueryKey, useShopMemberQuery, useUserProfileQuery } from "../client/query-client";
import { useDialog } from "../context/DialogContext";

type UserCardProps = {
    user: ShopMemberUser;
    disableMenu?: boolean;
}
const UserCard = ({ user, disableMenu }: UserCardProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const userProfile = useUserProfileQuery()
    const [openEditUserDialog, setOpenEditUserDialog] = useState<boolean>(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const handleCloseEditUserDialog = () => {
        setOpenEditUserDialog(false)
    }
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenDeleteDialog(false)
    };
    const [role, setRole] = useState<ShopMemberUser.shopRole | undefined>(user.shopRole)
    const { shopId } = useParams();
    const queryClient = useQueryClient()
    const dialog = useDialog()
    const motalClient = useMotalClient()
    const deleteUserMutation = useMutation({ mutationKey: ['user', shopId, 'delete'], mutationFn: (req: { email: string }) => motalClient.shop.shopMemberRemove({ shopId: shopId || '', requestBody: { emailAddress: req.email } }) })
    const handleDeleteButtonClick = () => {
        setOpenDeleteDialog(true)
    }
    const userUpdateMutation = useMutation({ mutationKey: ['user', shopId, 'update'], mutationFn: (req: { role: ShopMemberUser.shopRole }) => motalClient.shop.shopMemberUpdate({ shopId: shopId || '', requestBody: { emailAddress: user.primaryEmail, role: req.role as unknown as ShopMember.role } }) })
    const handleConfirmEdit = () => {
        setOpenEditUserDialog(false)
        setAnchorEl(null);
        userUpdateMutation.mutate({ role: role as ShopMemberUser.shopRole }, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [QueryKey.USER, shopId as string], exact: false })
                toast.success('แก้ไขผู้ใช้งานสำเร็จ')
            },
            onError: (error) => {
                dialog.showDialog({ title: 'เกิดข้อผิดพลาด', description: error.message, onConfirm: () => { } })
            }
        })
        setOpenEditUserDialog(false)
    }
    const handleConfirmDelete = () => {
        setOpenDeleteDialog(false)
        setAnchorEl(null);

        deleteUserMutation.mutate({ email: user.primaryEmail || '' }, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [QueryKey.USER, shopId as string], exact: false })
                toast.success('ลบผู้ใช้งานสำเร็จ')
            },
            onError: (error) => {
                dialog.showDialog({ title: 'เกิดข้อผิดพลาด', description: error.message, onConfirm: () => { } })
            }
        })
        setOpenDeleteDialog(false)
    }
    return (

        <Card sx={{ width: { xs: '100%', md: '400px' } }}>
            <CardContent>
                <Dialog
                    fullWidth
                    open={openEditUserDialog}
                    onClose={handleCloseEditUserDialog}

                >
                    <DialogTitle>แก้ไขข้อมูลผู้ใช้งาน</DialogTitle>
                    <DialogContent >
                        <Stack gap={2}>
                            <Box>
                                <DialogContentText sx={{ pb: 1 }}>
                                    อีเมล
                                </DialogContentText>
                                <TextField
                                    value={user.primaryEmail}
                                    disabled
                                    id="email"
                                    name="email"
                                    label="อีเมล"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Box>
                            <Box>
                                <DialogContentText sx={{ pb: 1 }}>
                                    หน้าที่
                                </DialogContentText>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={role}
                                    onChange={(e) => { setRole(e.target.value as ShopMemberUser.shopRole) }}
                                >
                                    <MenuItem value={ShopMember.role.OWNER}>เจ้าของร้าน</MenuItem>
                                    <MenuItem value={ShopMember.role.STAFF}>พนักงาน</MenuItem>
                                </Select>


                            </Box>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction='row' gap={2} p={2}>
                            <Button fullWidth size='large' variant="outlined" onClick={handleCloseEditUserDialog}>ยกเลิก</Button>
                            <Button onClick={handleConfirmEdit} fullWidth size='large' variant="contained" >บันทึก</Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={2} alignItems='flex-start'>
                    <Grid xs={2} item >
                        <Box py={1}>
                            <Avatar alt={user.firstName} sx={{ width: 48, height: 48 }} src={''} />
                        </Box>
                    </Grid>
                    <Grid xs={8} item>
                        <Typography variant="h6" component="div">
                            {user.firstName || user.laseName ? `${user.firstName} ${user.laseName}` : '-'}
                        </Typography>
                        <Typography color="text.secondary">
                            {user.primaryEmail}
                        </Typography>
                        <Typography variant="body2">
                            {user.shopRole === 'OWNER' ? 'เจ้าของร้าน' : user.shopRole === 'STAFF' ? 'พนักงาน' : '-'}
                        </Typography>
                    </Grid>
                    <Grid xs={2} item>
                        {!(user.primaryEmail === userProfile.data?.primaryEmail) && <IconButton
                            onClick={handleClick}
                        ><MoreVertOutlinedIcon />
                        </IconButton>}
                        <Menu
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => setOpenEditUserDialog(true)}>แก้ไข</MenuItem>
                            <MenuItem onClick={handleDeleteButtonClick}>ลบ</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </CardContent>

            <Dialog open={openDeleteDialog} onClose={handleClose}>
                <DialogTitle>ลบผู้ใช้งาน</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        คุณต้องการลบ
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack direction='row' justifyContent='space-between' gap={1} p={1}>
                        <Button fullWidth variant='outlined' onClick={handleClose}>ยกเลิก</Button>
                        <Button onClick={handleConfirmDelete} fullWidth variant='contained' color="error">ลบ</Button>
                    </Stack>
                </DialogActions>
            </Dialog>

        </Card>
    );
};

export const UserListScreen = () => {
    const [openUserDialog, setOpenUserDialog] = useState<boolean>(false)
    const form = useForm<{ email: string, role: ShopMemberInvitationRequest.role }>({ defaultValues: { email: '', role: ShopMember.role.STAFF } })

    const motalClient = useMotalClient()
    const { shopId } = useParams();
    const createUserMutation = useMutation({ mutationKey: ['user'], mutationFn: (req: { email: string, role: ShopMemberInvitationRequest.role }) => motalClient.shop.shopMemberCreateInvitation({ shopId: shopId || '', requestBody: { emailAddress: req.email, role: req.role } }) })
    const shopMemberQuery = useShopMemberQuery(shopId || '')
    const handleClose = () => {
        setOpenUserDialog(false);
    };
    const queryClient = useQueryClient()
    const dialog = useDialog()
    const handleSubmit = () => {
        form.handleSubmit((data) => {
            setOpenUserDialog(false)
            createUserMutation.mutate({ email: data.email, role: data.role }, {
                onSuccess: () => {
                    toast.success('เพิ่มผู้ใช้งานสำเร็จ')
                    form.reset({})
                    queryClient.invalidateQueries({ queryKey: [QueryKey.USER, shopId as string], exact: false })
                },
                onError: (error) => {
                    dialog.showDialog({ title: 'เกิดข้อผิดพลาด', description: error.message, onConfirm: () => { } })
                }
            })
        })()
    }

    return (
        <Box sx={{ p: 2 }}>
            <Dialog
                fullWidth
                open={openUserDialog}
                onClose={handleClose}

            >
                <DialogTitle>เพิ่มผู้ใช้งาน</DialogTitle>
                <DialogContent >
                    <Stack gap={2}>
                        <Box>
                            <DialogContentText sx={{ pb: 1 }}>
                                อีเมล
                            </DialogContentText>
                            <TextField
                                {...form.register('email', { required: true })}
                                required
                                id="email"
                                name="email"
                                label="อีเมล"
                                type="email"
                                fullWidth
                                variant="outlined"
                            />
                        </Box>
                        <Box>
                            <DialogContentText sx={{ pb: 1 }}>
                                หน้าที่
                            </DialogContentText>
                            <Controller
                                name="role"
                                control={form.control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={field.value}
                                    >
                                        <MenuItem value={ShopMember.role.OWNER}>เจ้าของร้าน</MenuItem>
                                        <MenuItem value={ShopMember.role.STAFF}>พนักงาน</MenuItem>
                                    </Select>
                                )}
                            />

                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack direction='row' gap={2} p={2}>
                        <Button fullWidth size='large' variant="outlined" onClick={() => { setOpenUserDialog(false) }}>ยกเลิก</Button>
                        <Button onClick={handleSubmit} fullWidth size='large' variant="contained" >บันทึก</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Stack gap={2}>
                <Stack direction={{ lg: 'row' }} gap={2} justifyContent='space-between' alignItems='flex-start'>
                    <TextField
                        sx={{ backgroundColor: 'white' }}
                        variant="outlined"
                        placeholder="ค้าหา ชื่อ-นามสกุล, อีเมล"
                        fullWidth
                    />
                    <Button
                        variant='contained'
                        size='medium'
                        onClick={() => { setOpenUserDialog(true) }}
                        // onClick={() => useBlacklistSearchQuery.fet}
                        sx={{ height: '56px', width: { xs: '100%', lg: '15%' } }}
                    >
                        เพิ่มผู้ใช้งาน
                    </Button>
                </Stack>
                <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" >
                    {shopMemberQuery.data?.shopMemberUsers?.map((user) => (
                        <UserCard key={user.id} user={user} />
                    ))}
                </Stack>
            </Stack>
        </Box>
    )
}