/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedResponseUser } from '../models/PaginatedResponseUser';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns User OK
     * @throws ApiError
     */
    public getProfile(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/me',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedResponseUser OK
     * @throws ApiError
     */
    public searchUsers({
        shopId,
        email,
        search,
        page = 1,
        limit = 10,
    }: {
        shopId?: string,
        email?: string,
        search?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedResponseUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users',
            query: {
                'shopId': shopId,
                'email': email,
                'search': search,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns User OK
     * @throws ApiError
     */
    public getUserProfile({
        userId,
    }: {
        userId: string,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
