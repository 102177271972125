/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoanEvent } from '../models/LoanEvent';
import type { PaginatedResponseLoanEvent } from '../models/PaginatedResponseLoanEvent';
import type { StorageEvent } from '../models/StorageEvent';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LoanService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PaginatedResponseLoanEvent OK
     * @throws ApiError
     */
    public searchLoans({
        shopId,
        search,
        scheduledDaysFrom,
        scheduledDaysTo,
        status,
        transactionDateFrom,
        transactionDateTo,
        sortBy = 'transactionDate',
        sort = 'desc',
        page = 1,
        limit = 10,
    }: {
        shopId: string,
        search?: string,
        scheduledDaysFrom?: number,
        scheduledDaysTo?: number,
        status?: Array<'NORMAL' | 'CLOSED' | 'REFUND' | 'FOLLOWING_UP' | 'CANNOT_CONTACT_CUSTOMER' | 'NON_PERFORMING' | 'REPORTED' | 'CANCEL'>,
        transactionDateFrom?: string,
        transactionDateTo?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedResponseLoanEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/loans',
            query: {
                'shopId': shopId,
                'search': search,
                'scheduledDaysFrom': scheduledDaysFrom,
                'scheduledDaysTo': scheduledDaysTo,
                'status': status,
                'transactionDateFrom': transactionDateFrom,
                'transactionDateTo': transactionDateTo,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanEvent OK
     * @throws ApiError
     */
    public createLoan({
        requestBody,
    }: {
        requestBody: LoanEvent,
    }): CancelablePromise<LoanEvent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/loans',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanEvent OK
     * @throws ApiError
     */
    public getLoan({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<LoanEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/loans/{loanId}',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns LoanEvent OK
     * @throws ApiError
     */
    public updateLoan({
        loanId,
        requestBody,
    }: {
        loanId: string,
        requestBody: LoanEvent,
    }): CancelablePromise<LoanEvent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/loans/{loanId}',
            path: {
                'loanId': loanId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns StorageEvent OK
     * @throws ApiError
     */
    public getLoanUploadUrl({
        loanId,
        fileName,
    }: {
        loanId: string,
        fileName: string,
    }): CancelablePromise<StorageEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/loans/{loanId}/upload-url',
            path: {
                'loanId': loanId,
            },
            query: {
                'fileName': fileName,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns StorageEvent OK
     * @throws ApiError
     */
    public getLoanDownloadUrl({
        loanId,
        fileName,
    }: {
        loanId: string,
        fileName: string,
    }): CancelablePromise<StorageEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/loans/{loanId}/download-url',
            path: {
                'loanId': loanId,
            },
            query: {
                'fileName': fileName,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns StorageEvent OK
     * @throws ApiError
     */
    public deleteFile1({
        loanId,
        fileName,
    }: {
        loanId: string,
        fileName: string,
    }): CancelablePromise<StorageEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/loans/{loanId}/delete-file',
            path: {
                'loanId': loanId,
            },
            query: {
                'fileName': fileName,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedResponseLoanEvent OK
     * @throws ApiError
     */
    public searchBlacklistLoans({
        search,
        scheduledDaysFrom,
        scheduledDaysTo,
        sortBy = 'transactionDate',
        sort = 'desc',
        page = 1,
        limit = 20,
    }: {
        search?: string,
        scheduledDaysFrom?: number,
        scheduledDaysTo?: number,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedResponseLoanEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/loans/search-blacklist',
            query: {
                'search': search,
                'scheduledDaysFrom': scheduledDaysFrom,
                'scheduledDaysTo': scheduledDaysTo,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns binary OK
     * @throws ApiError
     */
    public downloadLoans({
        shopId,
        search,
        scheduledDaysFrom,
        scheduledDaysTo,
        status,
        transactionDateFrom,
        transactionDateTo,
        sortBy = 'transactionDate',
        sort = 'desc',
    }: {
        shopId: string,
        search?: string,
        scheduledDaysFrom?: number,
        scheduledDaysTo?: number,
        status?: Array<'NORMAL' | 'CLOSED' | 'REFUND' | 'FOLLOWING_UP' | 'CANNOT_CONTACT_CUSTOMER' | 'NON_PERFORMING' | 'REPORTED' | 'CANCEL'>,
        transactionDateFrom?: string,
        transactionDateTo?: string,
        sortBy?: string,
        sort?: string,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/loans/download',
            query: {
                'shopId': shopId,
                'search': search,
                'scheduledDaysFrom': scheduledDaysFrom,
                'scheduledDaysTo': scheduledDaysTo,
                'status': status,
                'transactionDateFrom': transactionDateFrom,
                'transactionDateTo': transactionDateTo,
                'sortBy': sortBy,
                'sort': sort,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
