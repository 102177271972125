import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { PaymentChannel } from "../../client/motal-api/generated";
import { Delete } from "@mui/icons-material";
type Props = {
    paymentChannel: PaymentChannel
    onDelete?: () => void
}
const BankPaymentMethod = (props: Props) => {
    return (
        <Box sx={{ border: '2px solid #eeeeee', borderRadius: 2, p: 1, backgroundColor: '#0A2A4D' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' gap={1} alignItems='center'>
                    <Box p={1}>
                        <img height={28} src={'/bank-logo/prompt-pay-logo.svg'} alt='' />
                        {/* <img style={{color:'white' }} height={28} src={'/bank-logo/pp.svg'} alt='' /> */}

                    </Box>
                    <Stack justifyContent='flex-start' alignItems='flex-start'>
                        <Typography color='white' sx={{ textShadow: '1px 1px 1px #263238' }}>{props.paymentChannel.accountId}</Typography>
                        <Typography variant='body2' color='white' sx={{ textShadow: '1px 1px 1px #263238' }}>{props.paymentChannel.accountName || '-'}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row'>
                    {props.onDelete && <IconButton
                        sx={{ color: 'white' }}
                        onClick={() => {
                            if (props.onDelete)
                                props.onDelete()
                        }}>
                        {/* <ContentCopyIcon sx={{ color: 'white' }} /> */}
                        <Delete />
                    </IconButton>}
                    {props.paymentChannel.status === PaymentChannel.status.INACTIVE && <Chip label="เลิกใช้งาน" variant="outlined" sx={{ borderRadius: '3px', marginRight: '3px', color: 'white', backgroundColor: 'gray' }} />}

                    {/* <IconButton onClick={() => { }}>
                        <ArrowForwardIosIcon sx={{ color: 'white' }} />
                    </IconButton> */}
                </Stack>
            </Stack>
        </Box>
    );
}
export default BankPaymentMethod