import { Card, CardActionArea, CardContent, Typography, CardActions, Button, Avatar, IconButton, Stack, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useMotalClient } from "../client/motal-api";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useState } from "react";

export const PaymentPreviewFileCard = (props: { file: string, onDelete?: () => void, onDownload?: () => void, paymentId: string }) => {
    const client = useMotalClient();
    const response = useQuery({ queryKey: ["file", props.file], queryFn: (req) => client.payment.getPaymentDownloadUrl({ fileName: props.file, paymentId: props.paymentId }) });
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column' }} >
            <FilePreviewDialog url={response.data?.url || ''} onClose={() => setOpen(false)} open={open} fileName={props.file} />

            <CardActionArea onClick={() => {
                if (props.file.endsWith('.pdf')) {
                    window.open(response.data?.url, '_blank');
                } else {
                    setOpen(true);
                }
            }}>
                <CardContent>
                    <Avatar sx={{ width: 100, height: 100 }} variant="rounded" src={response.data?.url} style={{ objectFit: 'cover' }}>
                        <Typography gutterBottom component="div" >
                            {props.file}
                        </Typography>
                    </Avatar>

                    {/* <Typography variant="body2" color="text.secondary">
                        {file} bytes
                    </Typography> */}
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Stack direction='row' sx={{ justifyContent: 'space-between', width: '100%' }}>
                    <IconButton onClick={props.onDownload}><FileDownloadIcon /></IconButton>
                    <IconButton onClick={props.onDelete}><DeleteForeverIcon /></IconButton>
                </Stack>
            </CardActions>
        </Card>
    );
}

export const FilePreviewDialog = (props: { url: string, onClose: () => void, open: boolean, fileName: string }) => {
    return (
        <Dialog open={props.open} onClose={() => props.onClose()} >
            <DialogTitle>File Preview</DialogTitle>
            <DialogContent>
                <img src={props.url} alt="File Preview" style={{ width: "100%", height: "auto" }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}