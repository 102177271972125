/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopMemberUpdateRequest = {
    emailAddress?: string;
    role?: ShopMemberUpdateRequest.role;
};
export namespace ShopMemberUpdateRequest {
    export enum role {
        OWNER = 'OWNER',
        STAFF = 'STAFF',
    }
}

