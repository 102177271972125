
export function validateThaiId(id: string): boolean {
  if (id.length !== 13) return false;
  // STEP 1 - get only first 12 digits
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    // STEP 2 - multiply each digit with each index (reverse)
    // STEP 3 - sum multiply value together
    sum += parseInt(id.charAt(i)) * (13 - i);
  }
  // STEP 4 - mod sum with 11
  let mod = sum % 11;
  // STEP 5 - subtract 11 with mod, then mod 10 to get unit
  let check = (11 - mod) % 10;
  // STEP 6 - if check is match the digit 13th is correct
  if (check === parseInt(id.charAt(12))) {
    return true;
  }
  return false;
}


