
import { PaymentChannel } from "../../client/motal-api/generated"
import BankPaymentMethod from "./BankPaymentChannel"
import PromptPayPaymentMethod from "./PromptPayPaymentChannel"
import QrPaymentMethod from "./QrPaymentChannel"

type Props = {
    paymentChannel: PaymentChannel
    onDelete?: () => void
    hideIcon?: boolean
}
const PaymentMethod = (props: Props) => {
    return (
        <>
            {props.paymentChannel.type === PaymentChannel.type.BANK_ACCOUNT && <BankPaymentMethod paymentChannel={props.paymentChannel} onDelete={props.onDelete} />}
            {props.paymentChannel.type === PaymentChannel.type.PROMPT_PAY && <PromptPayPaymentMethod paymentChannel={props.paymentChannel} onDelete={props.onDelete} />}
            {props.paymentChannel.type === PaymentChannel.type.PROMPT_PAY_QR && <QrPaymentMethod paymentChannel={props.paymentChannel} onDelete={props.onDelete} hideIcon={props.hideIcon} />}
        </>
    )
}
export default PaymentMethod